import {createAsyncThunk, createSlice, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import _ from "lodash";
import {
    CALCULATE_PRICE_REGULAR_777_PATH,
    CALCULATE_PRICE_SHITATI_777_PATH, calculatePrice,
    SEND_FORM_REGULAR_777_PATH,
    SEND_FORM_SHITATI_777_PATH, sendForm
} from "../regularLotto/regullarLottoApi";
import {RowItem} from "../regularLotto/regularLottoSlice";

export interface AddNumberPayload {
    index: number;
    value: number;
}

export interface AutomaticFillPayload {
    index: number;
    numbersLength: number;
}

export enum TripleSevenFormTypes {
    REGULAR_777 = 'regular_777',
    SHITATI_777 = 'shitati_777',
}

export type RequestStatus = 'idle' | 'loading' | 'failed';

export interface TripleSevenState {
    tables: RowItem[];
    cost: number;
    extra: boolean;
    multi_lottery: number;
    costStatus: RequestStatus;
    sendFormStatus: RequestStatus;
    maximumRowsAllowed: number;
    formType: TripleSevenFormTypes;
    formSize: number;
    maxSelectedNumbers: number;
    form_type: number;
    error?: any;
}

const initialState: TripleSevenState = {
    tables: Array.from(Array(3), (_, index) => ({numbers: [], strong_number: [], table_number: index + 1})),
    extra: false,
    multi_lottery: -1,
    cost: 0,
    costStatus: 'idle',
    sendFormStatus: 'idle',
    maximumRowsAllowed: 7,
    formType: TripleSevenFormTypes.REGULAR_777,
    formSize: 3,
    maxSelectedNumbers: 7,
    form_type: 7,
};

function getPathByType(type: TripleSevenFormTypes): Record<'calculate' | 'send', URL> {
    switch (type) {
        case TripleSevenFormTypes.SHITATI_777:
            return {calculate: CALCULATE_PRICE_SHITATI_777_PATH, send: SEND_FORM_SHITATI_777_PATH};
        case TripleSevenFormTypes.REGULAR_777:
        default:
            return {calculate: CALCULATE_PRICE_REGULAR_777_PATH, send: SEND_FORM_REGULAR_777_PATH};
    }
}

export const calculateGamePrice777 = createAsyncThunk(
    'tripleSeven/calculatePrice',
    async (data: any) => {
        const path = getPathByType(data.formType).calculate;
        const response = await calculatePrice(data, path);
        // The value we return becomes the `fulfilled` action payload
        return await response.json();
    }
);

export const send777 = createAsyncThunk(
    'tripleSeven/sendForm',
    async (data: any, {rejectWithValue}) => {
        const path = getPathByType(data.data.formType).send;
        const response = await sendForm(data, path);
        if (response.status !== 200) {
            return rejectWithValue(await response.json())
        }
        return await response.json();
    }
);

export const tripleSevenSlice = createSlice({
    name: 'tripleSeven',
    initialState,
    reducers: {
        addNumber: (state, action: PayloadAction<AddNumberPayload>) => {
            let numbers = state.tables[action.payload.index].numbers;
            if (numbers.includes(action.payload.value)) return;
            numbers.push(action.payload.value);
            numbers.sort((a: number, b: number) => a - b);
        },
        removeNumber: (state, action: PayloadAction<AddNumberPayload>) => {
            state.tables[action.payload.index].numbers = state.tables[action.payload.index].numbers.filter(n => n !== action.payload.value)
        },
        automaticFillRow: (state, action: PayloadAction<AutomaticFillPayload>) => {
            let numbers = state.tables[action.payload.index].numbers;
            while (numbers.length < action.payload.numbersLength) {
                const val = _.random(1, 70)
                if (!numbers.includes(val)) {
                    numbers.push(val);
                    numbers.sort((a: number, b: number) => a - b);
                }
            }
        },
        clearRow: (state, action: PayloadAction<number>) => {
            state.tables[action.payload].numbers = [];
        },
        clearTable: (state) => {
            for (const row of state.tables) {
                row.numbers = [];
            }
            state.cost = 0;
        },
        autoFillTable: (state, action: PayloadAction<AutomaticFillPayload>) => {
            for (let i = 0; i < state.formSize; i++) {
                let numbers = state.tables[i].numbers;
                numbers.length = 0;
                while (numbers.length < action.payload.numbersLength) {
                    const val = _.random(1, 70)
                    if (!numbers.includes(val)) {
                        numbers.push(val);
                        numbers.sort((a: number, b: number) => a - b);
                    }
                }
            }
        },
        setFormSize: (state, action: PayloadAction<number>) => {
            state.formSize = action.payload;
        },
        setFormType: (state, action: PayloadAction<TripleSevenFormTypes>) => {
            switch (action.payload) {
                case TripleSevenFormTypes.REGULAR_777:
                    state.tables = Array.from(Array(3), (_, index) => ({
                        numbers: [],
                        strong_number: [],
                        table_number: index + 1
                    }))
                    state.formSize = 3;
                    state.maximumRowsAllowed = 3;
                    state.maxSelectedNumbers = 7;
                    state.form_type = 7;
                    break;
                case TripleSevenFormTypes.SHITATI_777:
                    state.tables = Array.from(Array(1), (_, index) => ({
                        numbers: [],
                        strong_number: [],
                        table_number: index + 1
                    }))
                    state.formSize = 1;
                    state.maximumRowsAllowed = 1;
                    state.maxSelectedNumbers = 8;
                    state.form_type = 8
                    break;
            }
            state.formType = action.payload;
        },
        setMaxNumber: (state, action: PayloadAction<number>) => {
            state.maxSelectedNumbers = Number(action.payload);
            state.form_type = Number(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                isAnyOf(calculateGamePrice777.pending), (state) => {
                    state.costStatus = 'loading';
                })
            .addMatcher(
                isAnyOf(calculateGamePrice777.fulfilled), (state, action) => {
                    state.costStatus = 'idle';
                    state.cost = action.payload['price'];
                }
            )
            .addMatcher(
                isAnyOf(calculateGamePrice777.rejected), (state) => {
                    state.costStatus = 'failed';
                }
            )
            .addMatcher(
                isAnyOf(send777.pending), (state) => {
                    state.sendFormStatus = 'loading';
                }
            )
            .addMatcher(
                isAnyOf(send777.fulfilled), (state, action) => {
                    state.sendFormStatus = 'idle';
                    // state.cost = action.payload['price'];
                }
            )
            .addMatcher(
                isAnyOf(send777.rejected), (state, action) => {
                    state.sendFormStatus = 'failed';
                    state.error = action.payload;
                }
            )
    },
});

export const {
    addNumber,
    removeNumber,
    automaticFillRow,
    clearRow,
    setFormSize,
    setFormType,
    clearTable,
    autoFillTable,
    setMaxNumber,
} = tripleSevenSlice.actions;
export const tripleSevenData = (state: RootState) => state.tripleSeven;
export const tripleSevenTables = (state: RootState) => state.tripleSeven.tables;
export const tripleSevenCost = (state: RootState) => state.tripleSeven.cost;
export const tripleSevenCostStatus = (state: RootState) => state.tripleSeven.costStatus;
export const tripleSevenSendFormStatus = (state: RootState) => state.tripleSeven.sendFormStatus;
export const tripleSevenSendFormError = (state: RootState) => state.tripleSeven.error;
export const tripleSevenFormSize = (state: RootState) => state.tripleSeven.formSize;
export const tripleSevenMaximumRowsAllowed = (state: RootState) => state.tripleSeven.maximumRowsAllowed;
export const tripleSevenFormType = (state: RootState) => state.tripleSeven.formType;
export const tripleSevenMaxNumber = (state: RootState) => state.tripleSeven.maxSelectedNumbers;

export default tripleSevenSlice.reducer;
