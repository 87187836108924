import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Flex} from "@aws-amplify/ui-react";
import {
    getSelectedRow,
    setSelectedRowIndex,
    setShowKeyboard,
    setStrongMode
} from "../../features/keyboard/keyboardSlice";
import {oneTwoThreeMaxNumber, oneTwoThreeTables, RowItem123} from "../../features/oneTwoThree/oneTwoThreeSlice";

interface RegularLottoRowProps {
    index: number;
}

const BALL_SIZE = 47

export const OneTwoThreeRow: React.FC<RegularLottoRowProps> = (props) => {
    const {index} = props;
    const dispatch = useAppDispatch();
    const tablesData = useAppSelector(oneTwoThreeTables)
    const selectedRowIndex = useAppSelector(getSelectedRow);
    const maxSelectedNumbers = useAppSelector(oneTwoThreeMaxNumber);
    const [rowData, setRowData] = useState<RowItem123>()

    const filled = useMemo(() => tablesData[index] && Object.values(tablesData[index].numbers).every(item => item > -1), [index, tablesData])

    const onRowClick = useCallback(() => {
        dispatch(setStrongMode(false));
        dispatch(setSelectedRowIndex(index));
        dispatch(setShowKeyboard(true));
    }, [dispatch, index])

    useEffect(() => {
        setRowData(tablesData[index])
    }, [tablesData, index])

    return <Flex direction={'row-reverse'} gap={12}
                 style={{cursor: 'pointer'}} wrap={'wrap'} marginBottom={10} justifyContent={'center'}>
        {
            Array.from(Array(maxSelectedNumbers)).map((_, ballIndex) => {
                return <Flex justifyContent={'center'} alignItems={'center'} borderRadius={35}
                             backgroundColor={'#ffffff'} key={ballIndex}
                             border={`${index === selectedRowIndex ? '2px' : '1px'} solid #FF8355`}
                             width={BALL_SIZE} height={BALL_SIZE} onClick={onRowClick}>
                    {rowData?.numbers[ballIndex + 1]! > -1 ? rowData?.numbers[ballIndex + 1] : ''}
                </Flex>
            })
        }
    </Flex>
}
