import {SERVER_URL} from "../timer/timerAPI";

// regular lotto
export const CALCULATE_PRICE_PATH = new URL('/games/lotto/type/regular/calculate_price', SERVER_URL)
export const SEND_FORM_PATH = new URL('/games/lotto/type/regular/0', SERVER_URL)
export const CALCULATE_PRICE_DOUBLE_PATH = new URL('/games/lotto/type/regular_double/calculate_price', SERVER_URL)
export const SEND_FORM_DOUBLE_PATH = new URL('/games/lotto/type/regular_double/0', SERVER_URL)
// shitati
export const CALCULATE_PRICE_SYSTEM_PATH = new URL('/games/lotto/type/shitati/calculate_price', SERVER_URL)
export const SEND_FORM_SYSTEM_PATH = new URL('/games/lotto/type/shitati/0', SERVER_URL)
export const CALCULATE_PRICE_DOUBLE_SYSTEM_PATH = new URL('/games/lotto/type/double_shitati/calculate_price', SERVER_URL)
export const SEND_FORM_DOUBLE_SYSTEM_PATH = new URL('/games/lotto/type/double_shitati/0', SERVER_URL)
// shitati hazak
export const CALCULATE_PRICE_STRONG_PATH = new URL('/games/lotto/type/shitati_hazak/calculate_price', SERVER_URL)
export const SEND_FORM_STRONG_PATH = new URL('/games/lotto/type/shitati_hazak/0', SERVER_URL)
export const CALCULATE_PRICE_DOUBLE_STRONG_PATH = new URL('/games/lotto/type/double_shitati_hazak/calculate_price', SERVER_URL)
export const SEND_FORM_DOUBLE_STRONG_PATH = new URL('/games/lotto/type/double_shitati_hazak/0', SERVER_URL)
// regular 777
export const CALCULATE_PRICE_REGULAR_777_PATH = new URL('/games/777/type/regular/calculate_price', SERVER_URL)
export const SEND_FORM_REGULAR_777_PATH = new URL('/games/777/type/regular/0', SERVER_URL)
// shitati 777
export const CALCULATE_PRICE_SHITATI_777_PATH = new URL('/games/777/type/shitati/calculate_price', SERVER_URL)
export const SEND_FORM_SHITATI_777_PATH = new URL('/games/777/type/shitati/0', SERVER_URL)
// 123
export const CALCULATE_PRICE_123_PATH = new URL('/games/123/type/regular/calculate_price', SERVER_URL)
export const SEND_FORM_123_PATH = new URL('/games/123/type/regular/0', SERVER_URL)
// regular chance
export const CALCULATE_PRICE_REGULAR_CHANCE_PATH = new URL('/games/chance/type/regular/calculate_price', SERVER_URL)
export const SEND_FORM_REGULAR_CHANCE_PATH = new URL('/games/chance/type/regular/0', SERVER_URL)
// rav chance
export const CALCULATE_PRICE_RAV_CHANCE_PATH = new URL('/games/chance/type/rav/calculate_price', SERVER_URL)
export const SEND_FORM_RAV_CHANCE_PATH = new URL('/games/chance/type/rav/0', SERVER_URL)

// chance shitati
export const CALCULATE_PRICE_CHANCE_SHITATI_PATH = new URL('/games/chance/type/shitati/calculate_price', SERVER_URL)
export const SEND_FORM_CHANCE_SHTATI_PATH = new URL('/games/chance/type/shitati/0', SERVER_URL)

export async function calculatePrice(data: any, path: URL): Promise<Response> {
    return await fetch(path, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'sss'
        },
        body: JSON.stringify(data)
    })
}

export async function sendForm(data: any, path: URL): Promise<Response> {
    return await fetch(path, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: data.authToken
        },
        body: JSON.stringify(data.data)
    })
}
