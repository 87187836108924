import {SERVER_URL} from "../timer/timerAPI";

export const GET_USER_BALANCE_PATH = new URL('/user/balance', SERVER_URL)

export async function getUserBalanceApi(authToken: string): Promise<Response> {
    return await fetch(GET_USER_BALANCE_PATH.href, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken
        },
    })
}
