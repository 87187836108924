import {createAsyncThunk, createSlice, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {
    CALCULATE_PRICE_DOUBLE_PATH, CALCULATE_PRICE_DOUBLE_STRONG_PATH, CALCULATE_PRICE_DOUBLE_SYSTEM_PATH,
    CALCULATE_PRICE_PATH, CALCULATE_PRICE_STRONG_PATH, CALCULATE_PRICE_SYSTEM_PATH,
    calculatePrice, SEND_FORM_DOUBLE_PATH, SEND_FORM_DOUBLE_STRONG_PATH, SEND_FORM_DOUBLE_SYSTEM_PATH,
    SEND_FORM_PATH, SEND_FORM_STRONG_PATH, SEND_FORM_SYSTEM_PATH,
    sendForm
} from "./regullarLottoApi";
import _ from "lodash";

export interface RowItem {
    numbers: number[];
    strong_number: number[];
    table_number: number;
}

export interface AddNumberPayload {
    index: number;
    value: number;
}

export interface AutomaticFillPayload {
    index: number;
    numbersLength: number;
    strongNumberLength: number;
}

export enum FormTypes {
    REGULAR = 'regular_lotto',
    REGULAR_DOUBLE = 'regular_double_lotto',
    SYSTEM_LOTTO = 'lotto_shitati',
    SYSTEM_DOUBLE_LOTTO = 'double_lotto_shitati',
    STRONG_LOTTO = 'lotto_shitati_hazak',
    DOUBLE_STRONG_LOTTO = 'double_lotto_shitati_hazak',
}

export interface RegularLottoState {
    tables: RowItem[];
    cost: number;
    extra: boolean;
    multi_lottery: number;
    costStatus: 'idle' | 'loading' | 'failed';
    sendFormStatus: 'idle' | 'loading' | 'failed';
    maximumRowsAllowed: number;
    formType: FormTypes;
    formSize: number;
    maxSelectedNumbers: number;
    maxSelectedStrongNumbers: number;
    form_type?: string;
    error?: any;
}

const initialState: RegularLottoState = {
    tables: Array.from(Array(14), (_, index) => ({
        numbers: [],
        strong_number: [],
        table_number: index + 1,
    })),
    extra: false,
    multi_lottery: -1,
    cost: 0,
    costStatus: 'idle',
    sendFormStatus: 'idle',
    maximumRowsAllowed: 7,
    formType: FormTypes.REGULAR,
    formSize: 7,
    maxSelectedNumbers: 6,
    maxSelectedStrongNumbers: 1,
};

function getPathByType(type: FormTypes): Record<'calculate' | 'send', URL> {
    switch (type) {
        case FormTypes.REGULAR_DOUBLE:
            return {calculate: CALCULATE_PRICE_DOUBLE_PATH, send: SEND_FORM_DOUBLE_PATH};
        case FormTypes.SYSTEM_LOTTO:
            return {calculate: CALCULATE_PRICE_SYSTEM_PATH, send: SEND_FORM_SYSTEM_PATH};
        case FormTypes.SYSTEM_DOUBLE_LOTTO:
            return {calculate: CALCULATE_PRICE_DOUBLE_SYSTEM_PATH, send: SEND_FORM_DOUBLE_SYSTEM_PATH};
        case FormTypes.STRONG_LOTTO:
            return {calculate: CALCULATE_PRICE_STRONG_PATH, send: SEND_FORM_STRONG_PATH};
        case FormTypes.DOUBLE_STRONG_LOTTO:
            return {calculate: CALCULATE_PRICE_DOUBLE_STRONG_PATH, send: SEND_FORM_DOUBLE_STRONG_PATH};
        default:
        case FormTypes.REGULAR:
            return {calculate: CALCULATE_PRICE_PATH, send: SEND_FORM_PATH};
    }
}

export const calculateGamePrice = createAsyncThunk(
    'game/calculatePrice',
    async (data: any) => {
        const path = getPathByType(data.formType).calculate;
        const response = await calculatePrice(data, path);
        // The value we return becomes the `fulfilled` action payload
        return await response.json();
    }
);

export const sendLotto = createAsyncThunk(
    'lotto/sendForm',
    async (data: any, {rejectWithValue}) => {
        const path = getPathByType(data.data.formType).send;
        const response = await sendForm(data, path);
        if (response.status !== 200) {
            return rejectWithValue(await response.json())
        }
        return await response.json();
    }
);

export const regularLottoSlice = createSlice({
    name: 'regularLotto',
    initialState,
    reducers: {
        addNumber: (state, action: PayloadAction<AddNumberPayload>) => {
            let numbers = state.tables[action.payload.index].numbers;
            if (numbers.includes(action.payload.value)) return;
            numbers.push(action.payload.value);
            numbers.sort((a: number, b: number) => a - b);
        },
        addStrongNumber: (state, action: PayloadAction<AddNumberPayload>) => {
            if (state.tables[action.payload.index].strong_number.includes(action.payload.value)) return;
            state.tables[action.payload.index].strong_number.push(action.payload.value)
        },
        removeNumber: (state, action: PayloadAction<AddNumberPayload>) => {
            state.tables[action.payload.index].numbers = state.tables[action.payload.index].numbers.filter(n => n !== action.payload.value)
        },
        removeStrongNumber: (state, action: PayloadAction<AddNumberPayload>) => {
            state.tables[action.payload.index].strong_number = state.tables[action.payload.index].strong_number.filter(n => n !== action.payload.value)
        },
        automaticFillRow: (state, action: PayloadAction<AutomaticFillPayload>) => {
            let numbers = state.tables[action.payload.index].numbers;
            while (numbers.length < action.payload.numbersLength) {
                const val = _.random(1, 37)
                if (!numbers.includes(val)) {
                    numbers.push(val);
                    numbers.sort((a: number, b: number) => a - b);
                }
            }
            let strongNumber = state.tables[action.payload.index].strong_number;
            while (strongNumber.length < action.payload.strongNumberLength) {
                const val = _.random(1, 7)
                if (!strongNumber.includes(val)) {
                    strongNumber.push(val);
                    strongNumber.sort((a: number, b: number) => a - b);
                }
            }
        },
        clearRow: (state, action: PayloadAction<number>) => {
            state.tables[action.payload].numbers = [];
            state.tables[action.payload].strong_number = [];
        },
        clearTable: (state) => {
            for (const row of state.tables) {
                row.numbers = [];
                row.strong_number = [];
            }
            state.cost = 0;
        },
        autoFillTable: (state, action: PayloadAction<AutomaticFillPayload>) => {
            const formSize = [FormTypes.REGULAR, FormTypes.REGULAR_DOUBLE].includes(state.formType) ? state.formSize * 2 : state.formSize;
            for (let i = 0; i < formSize; i++) {
                let numbers = state.tables[i].numbers;
                numbers.length = 0;
                while (numbers.length < action.payload.numbersLength) {
                    const val = _.random(1, 37)
                    if (!numbers.includes(val)) {
                        numbers.push(val);
                        numbers.sort((a: number, b: number) => a - b);
                    }
                }
                let strongNumber = state.tables[i].strong_number;
                strongNumber.length = 0;
                while (strongNumber.length < action.payload.strongNumberLength) {
                    const val = _.random(1, 7)
                    if (!strongNumber.includes(val)) {
                        strongNumber.push(val);
                        strongNumber.sort((a: number, b: number) => a - b);
                    }
                }
            }
        },
        setFormSize: (state, action: PayloadAction<number>) => {
            state.formSize = action.payload;
        },
        setFormType: (state, action: PayloadAction<FormTypes>) => {
            switch (action.payload) {
                case FormTypes.REGULAR:
                    state.formSize = 7;
                    state.maximumRowsAllowed = 7;
                    state.maxSelectedNumbers = 6;
                    break;
                case FormTypes.REGULAR_DOUBLE:
                    state.formSize = 5;
                    state.maximumRowsAllowed = 5;
                    break;
                case FormTypes.SYSTEM_LOTTO:
                case FormTypes.SYSTEM_DOUBLE_LOTTO:
                    state.formSize = 1;
                    state.maximumRowsAllowed = 1;
                    state.maxSelectedNumbers = 8;
                    state.form_type = '8'
                    break;
                case FormTypes.STRONG_LOTTO:
                case FormTypes.DOUBLE_STRONG_LOTTO:
                    state.formSize = 1;
                    state.maximumRowsAllowed = 1;
                    state.maxSelectedNumbers = 7;
                    state.maxSelectedStrongNumbers = 4;
                    state.form_type = '4'
                    break;
            }
            state.formType = action.payload;
        },
        setMaxNumber: (state, action: PayloadAction<string>) => {
            state.maxSelectedNumbers = action.payload === '5=6' ? 5 : Number(action.payload)
            state.form_type = action.payload;
        },
        setMaxStrongNumber: (state, action: PayloadAction<string>) => {
            state.maxSelectedStrongNumbers = parseInt(action.payload);
            state.form_type = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                isAnyOf(calculateGamePrice.pending), (state) => {
                    state.costStatus = 'loading';
                })
            .addMatcher(
                isAnyOf(calculateGamePrice.fulfilled), (state, action) => {
                    state.costStatus = 'idle';
                    state.cost = action.payload['price'];
                }
            )
            .addMatcher(
                isAnyOf(calculateGamePrice.rejected), (state) => {
                    state.costStatus = 'failed';
                }
            )
            .addMatcher(
                isAnyOf(sendLotto.pending), (state) => {
                    state.sendFormStatus = 'loading';
                }
            )
            .addMatcher(
                isAnyOf(sendLotto.fulfilled), (state, action) => {
                    state.sendFormStatus = 'idle';
                    // state.cost = action.payload['price'];
                }
            )
            .addMatcher(
                isAnyOf(sendLotto.rejected), (state, action) => {
                    state.sendFormStatus = 'failed';
                    state.error = action.payload;
                }
            )
    },
});

export const {
    addNumber,
    addStrongNumber,
    removeStrongNumber,
    removeNumber,
    automaticFillRow,
    clearRow,
    setFormSize,
    setFormType,
    clearTable,
    autoFillTable,
    setMaxNumber,
    setMaxStrongNumber,
} = regularLottoSlice.actions;
// export const nextLottoData = (state: RootState) => state.timer.lotto;
export const regularLottoData = (state: RootState) => state.regularLotto;
export const regularLottoTables = (state: RootState) => state.regularLotto.tables;
export const regularLottoCost = (state: RootState) => state.regularLotto.cost;
export const lottoCostStatus = (state: RootState) => state.regularLotto.costStatus;
export const regularLottoSendFormStatus = (state: RootState) => state.regularLotto.sendFormStatus;
export const regularLottoSendFormError = (state: RootState) => state.regularLotto.error;
export const regularLottoFormSize = (state: RootState) => state.regularLotto.formSize;
export const regularLottoMaximumRowsAllowed = (state: RootState) => state.regularLotto.maximumRowsAllowed;
export const regularLottoFormType = (state: RootState) => state.regularLotto.formType;
export const lottoMaxNumber = (state: RootState) => state.regularLotto.maxSelectedNumbers;
export const lottoMaxStrongNumber = (state: RootState) => state.regularLotto.maxSelectedStrongNumbers;

export default regularLottoSlice.reducer;
