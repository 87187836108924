import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Flex} from "@aws-amplify/ui-react";
import {
    getSelectedRow,
    setSelectedRowIndex,
    setShowKeyboard,
    setStrongMode
} from "../../features/keyboard/keyboardSlice";
import {tripleSevenMaxNumber, tripleSevenTables} from "../../features/tripleSeven/tripleSevenSlice";
import {BALL_SIZE} from "../regularLotto/regullarLottoRow";
import {RowItem} from "../../features/regularLotto/regularLottoSlice";

interface RegularLottoRowProps {
    index: number;
}

export const TripleSevenRow: React.FC<RegularLottoRowProps> = (props) => {
    const {index} = props;
    const dispatch = useAppDispatch();
    const tablesData = useAppSelector(tripleSevenTables)
    const selectedRowIndex = useAppSelector(getSelectedRow);
    const maxSelectedNumbers = useAppSelector(tripleSevenMaxNumber);
    const [rowData, setRowData] = useState<RowItem>()

    const filled = useMemo(() => tablesData[index] && tablesData[index].numbers.length === maxSelectedNumbers, [index, maxSelectedNumbers, tablesData])

    const onRowClick = useCallback(() => {
        dispatch(setStrongMode(false));
        dispatch(setSelectedRowIndex(index));
        dispatch(setShowKeyboard(true));
    }, [dispatch, index])

    useEffect(() => {
        setRowData(tablesData[index])
    }, [tablesData, index])

    return <Flex direction={'row-reverse'} gap={12}
                 style={{cursor: 'pointer'}} wrap={'wrap'} marginBottom={10} justifyContent={'center'}>
        {
            Array.from(Array(maxSelectedNumbers)).map((_, ballIndex) => {
                return <Flex justifyContent={'center'} alignItems={'center'} borderRadius={35}
                             backgroundColor={'#ffffff'} key={ballIndex}
                             border={`${index === selectedRowIndex ? '2px' : '1px'} solid #FE50A1`}
                             width={BALL_SIZE} height={BALL_SIZE} onClick={onRowClick}>
                    {typeof rowData?.numbers[ballIndex] !== "undefined" ? rowData?.numbers[ballIndex] : ''}
                </Flex>
            })
        }
    </Flex>
}
