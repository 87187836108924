import {SERVER_URL} from "../timer/timerAPI";

export const LOTTO_RESULT_PATH = new URL('/results/lotto', SERVER_URL)
export const CHANCE_RESULT_PATH = new URL('/results/chance', SERVER_URL)
export const ONE_TWO_THREE_RESULT_PATH = new URL('/results/123', SERVER_URL)
export const TRIPLE_SEVEN_RESULT_PATH = new URL('/results/777', SERVER_URL)

export async function fetchResults(path: URL): Promise<Response> {
    return await fetch(path)
}
