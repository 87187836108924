import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import Keyboard from "../Keyboard";
import {Flex, Grid, useAuthenticator, useTheme, View} from "@aws-amplify/ui-react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {NavMenu} from "../navMenu";
import {Modal, useModal} from "../Modal/Modal";
import {LoadMoreCreditContent, ModalErrorContent} from "../Modal/ModalContent";
import {getSelectedRow, getShowKeyboard, setSelectedRowIndex} from "../../features/keyboard/keyboardSlice";
import {
    getShowAlert,
    setAlertText,
    setAlertVariant,
    setShowAlert
} from "../../features/app/appSlice";
import {
    addNumber,
    automaticFillRow, clearRow,
    clearTable, removeNumber, send777,
    setFormType, tripleSevenCost, tripleSevenCostStatus,
    tripleSevenData, tripleSevenFormSize,
    tripleSevenFormType,
    TripleSevenFormTypes,
    tripleSevenMaxNumber,
    tripleSevenSendFormError,
    tripleSevenSendFormStatus,
    tripleSevenTables
} from "../../features/tripleSeven/tripleSevenSlice";
import {LottoFooter} from "../regularLotto/LottoFooter";
import {TripleSevenForm} from "./tripleSevenForm";
import {AppAlert} from "../AppAlert";
import {useLocation, useNavigate} from "react-router-dom";

const TripleSevenContainer: React.FC<{ gameType: 'regular' | 'system' }> = (props) => {
    const {gameType} = props;
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const tablesData = useAppSelector(tripleSevenTables);
    const formData = useAppSelector(tripleSevenData);
    const sendFormStatus = useAppSelector(tripleSevenSendFormStatus);
    const sendFormError = useAppSelector(tripleSevenSendFormError);
    const showKeyboard = useAppSelector(getShowKeyboard);
    const {user} = useAuthenticator((context) => [context.user]);
    const {isShown, toggle} = useModal();
    const [showLoadCredit, setShowLoadCredit] = useState(false);
    const showAlert = useAppSelector(getShowAlert);
    const formType = useAppSelector(tripleSevenFormType);
    const selectedRowIndex = useAppSelector(getSelectedRow);
    const maxSelectedNumbers = useAppSelector(tripleSevenMaxNumber);
    const cost = useAppSelector(tripleSevenCost);
    const getCostStatus = useAppSelector(tripleSevenCostStatus);
    const formSize = useAppSelector(tripleSevenFormSize);
    const elRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        dispatch(clearTable())
        dispatch(setSelectedRowIndex(0));
        switch (gameType) {
            case 'system':
                dispatch(setFormType(TripleSevenFormTypes.SHITATI_777))
                break;
            case 'regular':
            default:
                dispatch(setFormType(TripleSevenFormTypes.REGULAR_777))
                break;
        }
    }, [dispatch, gameType])

    useEffect(() => {
        const offset = selectedRowIndex * 40
        elRef.current?.scroll({
            top: offset,
            behavior: 'smooth'
        })
    }, [elRef, selectedRowIndex])

    const modalContent = useMemo(() => {
        if (showLoadCredit) {
            return <LoadMoreCreditContent hide={toggle} setShowLoadCredit={setShowLoadCredit} formData={formData}/>
        } else if (sendFormStatus === 'failed') {
            return <ModalErrorContent hide={toggle} setShowLoadCredit={setShowLoadCredit}
                                      sendFormError={sendFormError}/>
        } else {
            return <>success !</>
        }
    }, [sendFormStatus, showLoadCredit, toggle])

    const modalHeader = useMemo(() => {
        if (showLoadCredit) {
            return 'טעינת קרדיט'
        } else if (sendFormStatus === 'failed') {
            return sendFormError.error;
        } else {
            return <>success !</>
        }
    }, [sendFormError?.error, sendFormStatus, showLoadCredit])


    const filledRows = useMemo(() => {
        return tablesData.filter(row => row.numbers.length === maxSelectedNumbers).length
    }, [maxSelectedNumbers, tablesData]);

    useEffect(() => {
        console.log(sendFormStatus)
        if (sendFormStatus === 'failed') {
            toggle()
        }
    }, [sendFormStatus])

    const onSendForm = useCallback(async () => {
        if (!user) {
            navigate('/login', {replace: true, state: {from: location}})
            return;
        }
        if (filledRows) {
            const authToken = user.getSignInUserSession()?.getAccessToken().getJwtToken();
            await dispatch(send777({authToken, data: formData}));
            dispatch(clearTable());
        } else {
            dispatch(setShowAlert(true));
            dispatch(setAlertVariant('error'));
            dispatch(setAlertText('עליך למלא את כל המספרים'));
        }
    }, [dispatch, formData, formType, user])

    const {tokens} = useTheme();

    return (
        <>
            {showAlert && <AppAlert/>}
            <Grid
                templateColumns={{base: '1fr', large: '2fr 2fr 1.5fr'}}
                templateRows={{base: 'repeat(3)', large: 'repeat(2)'}}
                gap={tokens.space.small}>
                <Flex order={[2, 2, 2, 1]} alignItems={'center'} justifyContent={'center'}>
                    {showKeyboard && <Keyboard maxSelectedNumbers={maxSelectedNumbers} numbersLength={70}
                                               maxSelectedStrongNumbers={0} formType={formType}
                                               automaticFillRow={automaticFillRow} clearRow={clearRow}
                                               clearTable={clearTable} formSize={formSize} tablesData={tablesData}
                                               addNumber={addNumber} removeNumber={removeNumber}/>}
                </Flex>
                <View order={[1, 1, 1, 2]}>
                    <TripleSevenForm elRef={elRef}/>
                </View>
                <Flex display={['none', 'none', 'none', 'flex']} justifyContent={'flex-end'} order={3}>
                    <NavMenu/>
                </Flex>
                <View order={3} columnSpan={[1, 1, 1, 3]}
                      style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}>
                    <LottoFooter filledRows={filledRows} onSendForm={onSendForm} sendFormStatus={sendFormStatus}
                                 cost={cost} getCostStatus={getCostStatus}/>
                </View>
                <Modal
                    isShown={isShown}
                    hide={toggle}
                    headerText={modalHeader}
                    modalContent={modalContent}
                />
            </Grid>
        </>
    )
}

export default TripleSevenContainer
