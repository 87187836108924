import React from "react";
import {Flex, Heading, Text} from "@aws-amplify/ui-react";
import {BsFacebook, BsInstagram, BsWhatsapp} from "react-icons/bs";
import {CONTACT_US_TEXT} from "./descriptionConsts";

export const GetInContact: React.FC = () => {
    return <Flex justifyContent={'center'} direction={'column'} alignItems={'center'} padding={15}>
        <Heading level={3}>צור קשר</Heading>
        <Text whiteSpace={'break-spaces'}>{CONTACT_US_TEXT}</Text>
        <Flex justifyContent={'center'} direction={'row'} alignItems={'center'} padding={15}>
            <BsWhatsapp style={{fontSize: 34}} onClick={() => window.location.replace("https://wa.me/message/QXZMWJ22QR6HH1")}/>
            <BsFacebook style={{fontSize: 34}} onClick={() => window.location.replace("https://www.facebook.com/profile.php?id=100067875755759")}/>
            <BsInstagram style={{fontSize: 34}} onClick={() => window.location.replace("https://www.instagram.com/lottomatic.io/?igshid=YmMyMTA2M2Y%3D")}/>
        </Flex>
    </Flex>
}
