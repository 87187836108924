import React from "react";
import {Flex, Grid, Heading, Text, useTheme} from "@aws-amplify/ui-react";
import {Link} from "react-router-dom";
import {NavMenu} from "../navMenu";
import {makeStyles} from "@material-ui/core/styles";
import {TEXT_777} from "../../utils/texts";

const useStyle = makeStyles(({
    buttonContainer: {
        width: '100%',
        backgroundColor: '#FE50A1',
        justifyContent: 'center',
        alignItems: 'center',
        height: 100,
        borderRadius: 6,
        textShadow: '1px 1px 2px',
        fontSize: 20,
        fontWeight: 900,
    }
}));

export const TripleSevenIndex: React.FC = () => {
    const classes = useStyle();
    const {tokens} = useTheme();
    return <Grid
        templateColumns={{base: '1fr', large: '1fr 4fr 1.5fr'}}
        templateRows={{base: 'repeat(3)', large: 'repeat(2)'}}
        gap={tokens.space.small}>
        <Flex/>
        <Flex direction={'column'} paddingInline={4} paddingTop={[4, 4, 4, 62]}>
            <Link to={'/regular-777'} style={{textDecoration: 'none'}}>
                <Flex className={classes.buttonContainer}>
                    <Text color={'rgba(255, 255, 255, 1)'}>הגרלת 777</Text>
                </Flex>
            </Link>
            <Link to={'/shitati-777'} style={{textDecoration: 'none'}}>
                <Flex className={classes.buttonContainer}>
                    <Text color={'rgba(255, 255, 255, 1)'}>הגרלת 777 שיטתי</Text>
                </Flex>
            </Link>
            <Flex direction={'column'} style={{direction: 'rtl'}} paddingTop={40}>
                <Heading>
                    הסבר על הגרלות 777
                </Heading>
                <Text color={'#868686'}>
                    {TEXT_777}
                </Text>
            </Flex>
        </Flex>
        <Flex display={['none', 'none', 'none', 'flex']} justifyContent={'flex-end'}>
            <NavMenu/>
        </Flex>
    </Grid>
}
