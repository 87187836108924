import React from "react";
import {Flex, Heading, Text} from "@aws-amplify/ui-react";
import {TERMS_OF_USE_TEXT, TERMS_OF_USE_TITLE} from "./descriptionConsts";

export const Terms: React.FC = () => {
    return <Flex justifyContent={'center'} direction={'column'} alignItems={'center'} padding={15}>
        <Heading level={3}>{TERMS_OF_USE_TITLE}</Heading>
        <Text whiteSpace={'break-spaces'}>{TERMS_OF_USE_TEXT}</Text>
    </Flex>
}

