import React, {useCallback, useEffect, useMemo, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Button} from "@aws-amplify/ui-react";
import {useAppDispatch} from "../app/hooks";
import classNames from 'classnames';
import {AddNumberPayload, FormTypes, RowItem} from "../features/regularLotto/regularLottoSlice";
import {TripleSevenFormTypes} from "../features/tripleSeven/tripleSevenSlice";
import {OneTwoThreeFormTypes} from "../features/oneTwoThree/oneTwoThreeSlice";

const useStyles = makeStyles(() => ({
    keyboardButton: {
        width: 36,
        height: 36,
        borderRadius: 35,
        boxShadow: '3px 4px 8px 0px rgba(202, 202, 202, 0.25)',
        color: 'black',
        border: 'none',
        fontWeight: 600,

        '&:focus': {
            backgroundColor: 'white',
            color: 'black'
        },

        '&.amplify-button--disabled': {
            opacity: 0.4,
        }
    },
    keyboardButton123: {
        width: 65,
        height: 65,
        borderRadius: 35,
        boxShadow: '3px 4px 8px 0px rgba(202, 202, 202, 0.25)',
        color: 'black',
        border: 'none',
        fontWeight: 600,
        marginRight: 15,

        '&:focus': {
            backgroundColor: 'white',
            color: 'black'
        },

        '&.amplify-button--disabled': {
            opacity: 0.4,
        }
    },
    selected: {
        border: '2px solid #FF1A1A',
    },
    strong: {
        color: '#FF1A1A',
    },
    selectedStrong: {
        color: 'white',
        backgroundColor: '#FF1A1A'
    }
}))

interface KeyboardButtonProps {
    number: number;
    selectedRowIndex: number;
    maxSelectedNumbers: number;
    tablesData: any;
    addNumber: (actionData: AddNumberPayload) => any;
    removeNumber: (actionData: AddNumberPayload) => any;
    formType: FormTypes | TripleSevenFormTypes | OneTwoThreeFormTypes;
    addStrongNumber?: (actionData: AddNumberPayload) => any;
    removeStrongNumber?: (actionData: AddNumberPayload) => any;
    isStrong?: boolean;
    isDisabled?: boolean;
}

export const KeyboardButton: React.FC<KeyboardButtonProps> = (props) => {
    const {
        selectedRowIndex,
        maxSelectedNumbers,
        number,
        isStrong,
        isDisabled,
        tablesData,
        addNumber,
        addStrongNumber,
        removeStrongNumber,
        removeNumber,
        formType,
    } = props;
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [rowData, setRowData] = useState<RowItem>()

    useEffect(() => {
        setRowData(tablesData[selectedRowIndex])
    }, [tablesData, selectedRowIndex])

    const isSelected = useMemo(() => {
        if (formType === OneTwoThreeFormTypes.REGULAR_123) {
            return false
        }
        return isStrong ? rowData?.strong_number.includes(number) : rowData?.numbers.includes(number) && !isDisabled;
    }, [formType, isStrong, rowData?.strong_number, rowData?.numbers, number, isDisabled])

    const isNumbersFilled = useMemo(() => {
        if (formType === OneTwoThreeFormTypes.REGULAR_123) {
            return Object.values(rowData?.numbers ?? []).every(item => item > -1)
        }
        return isStrong ? rowData?.strong_number.length === maxSelectedNumbers : rowData?.numbers.length === maxSelectedNumbers;
    }, [rowData, isStrong, maxSelectedNumbers])

    const onNumberClick = useCallback((value: number) => {
        // if (rowData && rowData.numbers.length >= maxSelectedNumbers && !isSelected) return;
        const actionData = {
            index: selectedRowIndex,
            value
        };
        let disFunc;
        if (isStrong && removeStrongNumber && addStrongNumber) {
            disFunc = isSelected ? removeStrongNumber(actionData) : addStrongNumber(actionData)
        } else {
            disFunc = isSelected ? removeNumber(actionData) : addNumber(actionData)
        }
        dispatch(disFunc)
    }, [selectedRowIndex, isStrong, dispatch, isSelected, removeStrongNumber, addStrongNumber, removeNumber, addNumber])


    let btnClass = classNames(classes.keyboardButton, isSelected ? isStrong ? classes.selectedStrong : classes.selected : '', isStrong ? classes.strong : '');
    if (formType === OneTwoThreeFormTypes.REGULAR_123) {
        btnClass = classes.keyboardButton123
    }
    return <Button disabled={(isNumbersFilled && !isSelected) || isDisabled} className={btnClass}
                   onClick={() => onNumberClick(number)}>{number}</Button>
}
