import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface KeyboardState {
    selectedRowIndex: number;
    strongMode: boolean;
    showKeyboard: boolean;
}

const initialState: KeyboardState = {
    selectedRowIndex: 0,
    strongMode: false,
    showKeyboard: true,
};


export const keyboardSlice = createSlice({
    name: 'keyboard',
    initialState,
    reducers: {
        setStrongMode: (state, action: PayloadAction<boolean>) => {
            state.strongMode = action.payload;
        },
        setSelectedRowIndex: (state, action: PayloadAction<number>) => {
            state.selectedRowIndex = action.payload;
        },
        setShowKeyboard: (state, action: PayloadAction<boolean>) => {
            state.showKeyboard = action.payload;
        },
    },
});

export const {setStrongMode, setSelectedRowIndex, setShowKeyboard} = keyboardSlice.actions;

export const getSelectedRow = (state: RootState) => state.keyboard.selectedRowIndex;
export const getIsStrongMode = (state: RootState) => state.keyboard.strongMode;
export const getShowKeyboard = (state: RootState) => state.keyboard.showKeyboard;
export default keyboardSlice.reducer;
