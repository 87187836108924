import React from 'react'
import {Link} from "react-router-dom";
import {NavMenu} from "./navMenu";
import {Flex} from "@aws-amplify/ui-react";

const LottoIndex: React.FC = () => {
    return (
        <section className="main-content_section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8 offset-lg-1">
                        <div className="main_center">
                            <ul className="center_listing">
                                <Link to={'/regular-lotto'}>
                                    <li><p>לוטו רגיל</p></li>
                                </Link>
                                <Link to={'/system-lotto'}>
                                    <li><p>שיטתי</p></li>
                                </Link>
                                <Link to={'/strong-lotto'}>
                                    <li><p>שיטתי חזק</p></li>
                                </Link>
                            </ul>
                            <div className="center_content_bottom">
                                <h5 className="cenetr_centent_heading">הסבר על הגרלות לוטו</h5>
                                <p className="center_content_para">
                                    לאחר התשלום אנו בלוטומטיק מקבלים את הטופס שמילאתם ושולחים אותו עבורכם בנקודת מכירה
                                    מורשית של מפעל הפיס, את הטופס שמילאנו עבורכם בנקודה אנו סורקים ושולחים לכם לתיבת
                                    הדואר האלקטרוני ומעלים את הטופס הסרוק לאזור האישי שלכם באפליקציה. הטופס המקורי יישמר
                                    אצלנו במשרדי החברה ובמידה וזכיתם בסכום העולה על 11,000 ש״ח יימסר לכם הטופס באופן
                                    אישי. במידה וסכום הזכייה שלכם הינו מ11,000 ש״ח או נמוך ממנו החשבון שלכם באתר יזוכה
                                    באופן אוטומטי ותקבלו על כך הודעה, אתם תוכלו למשוך את הכסף מהחשבון שכלם באתר לתוך
                                    חשבון הבנק שלכם או שתבחרו לנצל את היתרה לשליחת טפסים חדשים. אנו מאחלים לכם חוויה
                                    שימוש והנאה מרבית מהשירות שלנו ומלאי תקווה שנביא לכם את המזל. בהצלחה!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <Flex display={['none', 'none', 'none', 'flex']} justifyContent={'flex-end'}>
                            <NavMenu/>
                        </Flex>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LottoIndex
