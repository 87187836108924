import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {fetchTimes, GamesTypesEnum} from './timerAPI';

export interface NextGameData {
    time?: string;
    win_amount?: string;
}

export interface TimerState {
    lotto: NextGameData;
    pais: NextGameData;
    tripleSeven: NextGameData;
    oneTwoThree: NextGameData;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: TimerState = {
    lotto: {
        time: undefined,
        win_amount: undefined,
    },
    pais: {
        time: undefined,
        win_amount: undefined,
    },
    tripleSeven: {
        time: undefined,
        win_amount: undefined,
    },
    oneTwoThree: {
        time: undefined,
        win_amount: undefined,
    },
    status: 'idle',
};

export const getTimes = createAsyncThunk(
    'timer/fetchTimes',
    async () => {
        const response = await fetchTimes();
        // The value we return becomes the `fulfilled` action payload
        return await response.json();
    }
);

export const timerSlice = createSlice({
    name: 'timer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTimes.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTimes.fulfilled, (state, action) => {
                state.status = 'idle';
                state.lotto = action.payload[GamesTypesEnum.lotto];
                state.pais = action.payload[GamesTypesEnum.chance];
                state.tripleSeven = action.payload[GamesTypesEnum.tripleSeven];
                state.oneTwoThree = action.payload[GamesTypesEnum.oneTwoThree];
            })
            .addCase(getTimes.rejected, (state) => {
                state.status = 'failed';
            });
    },
});
export const nextLottoData = (state: RootState) => state.timer.lotto;
export const nextLotteryData = (state: RootState) => state.timer;

export default timerSlice.reducer;
