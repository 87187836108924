import React, {FunctionComponent, useEffect, useState} from "react";

import {Backdrop} from "./modal.style";
import {Flex, Text, View} from "@aws-amplify/ui-react";
import {makeStyles} from "@material-ui/core/styles";

export interface ModalProps {
    isShown: boolean;
    hide: () => void;
    modalContent: JSX.Element;
    headerText: string;
}

export const useModal = () => {
    const [isShown, setIsShown] = useState<boolean>(false);
    const toggle = () => setIsShown(!isShown);
    return {
        isShown,
        toggle,
    };
};

const useStyle = makeStyles(() => ({
    wrapper: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 700,
        outline: 0,
    },
    modal: {
        zIndex: 100,
        background: 'transparent',
        position: 'relative',
        margin: 'auto',
        borderRadius: 8,
    },
}))

export const Modal: FunctionComponent<ModalProps> = ({isShown, hide, modalContent, headerText}) => {
    const classes = useStyle();
    const onKeyDown = (event: KeyboardEvent) => {
        if (event.code === 'Escape' && isShown) {
            hide();
        }
    };

    useEffect(() => {
        isShown
            ? (document.body.style.overflow = "hidden")
            : (document.body.style.overflow = "unset");
        document.addEventListener("keydown", onKeyDown, false);
        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
        };
    }, [isShown]);

    const modal = (
        <React.Fragment>
            <Backdrop onClick={hide}/>
            <Flex direction={'column'} className={classes.wrapper} width={['100%', '100%', '100%', 'inherit']}>
                <Flex direction={'column'} className={classes.modal}>
                    <View borderRadius='8px 8px 0 0' display='flex' justifyContent={'center'} padding={0.3}>
                        <Text color={'#000C47'} fontSize={37} fontWeight={900} alignSelf={'center'}>{headerText}</Text>
                    </View>
                    <View padding={10} maxHeight={'30rem'} overflow={'auto'}>{modalContent}</View>
                </Flex>
            </Flex>
        </React.Fragment>
    )

    return isShown ? modal : null;
};
