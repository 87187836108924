import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {Button, Flex, Text} from "@aws-amplify/ui-react";
import {KeyboardButton} from "./KeyboardButton";
import {makeStyles} from "@material-ui/core/styles";
import {AiOutlineArrowDown, AiOutlineArrowUp, AiOutlineClose, AiOutlineDelete} from "react-icons/ai";
import {IoIosColorWand} from "react-icons/io";
import {
    getIsStrongMode,
    getSelectedRow,
    setSelectedRowIndex,
    setShowKeyboard,
    setStrongMode
} from "../features/keyboard/keyboardSlice";
import {AddNumberPayload, FormTypes, RowItem} from "../features/regularLotto/regularLottoSlice";
import {TripleSevenFormTypes} from "../features/tripleSeven/tripleSevenSlice";
import {OneTwoThreeFormTypes} from "../features/oneTwoThree/oneTwoThreeSlice";

interface KeyboardProps {
    maxSelectedNumbers: number;
    maxSelectedStrongNumbers: number;
    numbersLength: number;
    tablesData: any;
    formSize: number;
    formType: FormTypes | TripleSevenFormTypes | OneTwoThreeFormTypes;
    clearRow: (i: number) => any;
    clearTable: () => any;
    automaticFillRow: (val: any) => any;
    addNumber: (actionData: AddNumberPayload) => any;
    removeNumber: (actionData: AddNumberPayload) => any;
    addStrongNumber?: (actionData: AddNumberPayload) => any;
    removeStrongNumber?: (actionData: AddNumberPayload) => any;
}

const useStyles = makeStyles(() => ({
    keyboardContainer: {
        maxWidth: 360,
        alignItems: 'center',
        border: '2px solid #FFFFFF',
        backgroundColor: '#FCFCFC',
        borderRadius: 24,
        boxShadow: '8px 12px 14px rgba(0, 0, 0, 0.11)',
        padding: 4,
    }
}))

const Keyboard: React.FC<KeyboardProps> = (props) => {
    const {
        maxSelectedNumbers,
        maxSelectedStrongNumbers,
        tablesData,
        formSize,
        formType,
        clearRow,
        clearTable,
        automaticFillRow, addStrongNumber, removeStrongNumber, addNumber, removeNumber, numbersLength
    } = props;
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const numbers = useMemo(() => Array(numbersLength).fill(null).map((_, i) => formType === OneTwoThreeFormTypes.REGULAR_123 ? i : i + 1), [formType, numbersLength])
    const [rowData, setRowData] = useState<RowItem>()
    const selectedRowIndex = useAppSelector(getSelectedRow);
    const strongMode = useAppSelector(getIsStrongMode);

    useEffect(() => {
        setRowData(tablesData[selectedRowIndex])
    }, [tablesData, selectedRowIndex])

    const disabledNext = useMemo(() => {
        if (formType === FormTypes.REGULAR || formType === FormTypes.REGULAR_DOUBLE) {
            return selectedRowIndex + 1 >= formSize * 2
        } else if (formType === TripleSevenFormTypes.REGULAR_777 || formType === OneTwoThreeFormTypes.REGULAR_123) {
            return selectedRowIndex + 1 >= formSize
        }
    }, [formSize, formType, selectedRowIndex]);
    const disabledPrev = useMemo(() => ![FormTypes.REGULAR, FormTypes.REGULAR_DOUBLE, TripleSevenFormTypes.REGULAR_777, OneTwoThreeFormTypes.REGULAR_123].includes(formType) || selectedRowIndex - 1 < 0, [formType, selectedRowIndex]);

    const onNextRow = useCallback(() => {
        if (disabledNext) return;
        dispatch(setStrongMode(false));
        dispatch(setSelectedRowIndex(selectedRowIndex + 1));
    }, [disabledNext, dispatch, selectedRowIndex])

    const onPrevRow = useCallback(() => {
        if (disabledPrev) return;
        dispatch(setStrongMode(false));
        dispatch(setSelectedRowIndex(selectedRowIndex - 1));
    }, [disabledPrev, dispatch, selectedRowIndex])

    const isNumbersFilled = useMemo(() => {
        if (formType === OneTwoThreeFormTypes.REGULAR_123) {
            return Object.values(rowData?.numbers ?? []).every(item => item > -1)
        }
        return strongMode ? rowData?.strong_number.length === maxSelectedStrongNumbers : rowData?.numbers.length === maxSelectedNumbers;
    }, [selectedRowIndex, rowData?.strong_number?.length, rowData?.numbers?.length])

    const isRowFilled = useMemo(() => {
        if (formType === OneTwoThreeFormTypes.REGULAR_123) {
            return Object.values(rowData?.numbers ?? []).every(item => item > -1)
        }
        return rowData?.strong_number.length === maxSelectedStrongNumbers && rowData?.numbers.length === maxSelectedNumbers;
    }, [selectedRowIndex, rowData?.strong_number?.length, rowData?.numbers?.length])

    useEffect(() => {
        if (isNumbersFilled && !strongMode && addStrongNumber) {
            dispatch(setStrongMode(true));
        } else if (isRowFilled && (strongMode || !addStrongNumber)) {
            onNextRow()
            dispatch(setStrongMode(false));
        }
    }, [dispatch, isNumbersFilled, isRowFilled])

    const onClearRow = useCallback(() => {
        dispatch(clearRow(selectedRowIndex));
        if (selectedRowIndex > 0) {
            dispatch(setSelectedRowIndex(selectedRowIndex - 1));
        }
    }, [dispatch, selectedRowIndex])

    const onClearTable = useCallback(() => {
        dispatch(clearTable())
        dispatch(setSelectedRowIndex(0));
    }, [dispatch])

    const onHideKeyboard = useCallback(() => dispatch(setShowKeyboard(false)), [dispatch]);

    const automaticFill = useCallback(() => {
        dispatch(automaticFillRow({
            index: selectedRowIndex,
            numbersLength: maxSelectedNumbers,
            strongNumberLength: maxSelectedStrongNumbers,
        }))
        onNextRow()
    }, [dispatch, maxSelectedNumbers, maxSelectedStrongNumbers, onNextRow, selectedRowIndex])

    return (
        <Flex direction={'column'} className={classes.keyboardContainer}>
            <Flex direction={'row-reverse'} display={['none', 'none', 'none', 'flex']}>
                <Button onClick={automaticFill} width={150} backgroundColor={'#1E1E1E'}>מלא שורה</Button>
                <Button onClick={onClearRow} width={150} backgroundColor={'#1E1E1E'}>מחק שורה</Button>
            </Flex>
            <Flex display={['flex', 'flex', 'flex', 'none']} fontSize={20} width={'100%'}
                  justifyContent={'space-around'}>
                <Flex>
                    <Button disabled={disabledPrev} onClick={onPrevRow} color={'black'} padding={4}><AiOutlineArrowUp/></Button>
                    <Button disabled={disabledNext} onClick={onNextRow} color={'black'}
                            padding={4}><AiOutlineArrowDown/></Button>
                </Flex>
                <Flex>
                    <Button onClick={automaticFill} color={'black'} padding={4}><IoIosColorWand/></Button>
                    <Button onClick={onClearRow} color={'black'} padding={4}><AiOutlineDelete/></Button>
                </Flex>
                <Button onClick={onHideKeyboard} color={'black'} padding={4}><AiOutlineClose/></Button>
            </Flex>
            <Flex wrap={'wrap'} style={{direction: 'rtl'}}
                  justifyContent={formType === OneTwoThreeFormTypes.REGULAR_123 ? 'center' : 'flex-start'}>
                {
                    numbers.map((number) => {
                        return <KeyboardButton number={number} selectedRowIndex={selectedRowIndex}
                                               maxSelectedNumbers={strongMode ? maxSelectedStrongNumbers : maxSelectedNumbers}
                                               key={number} isDisabled={strongMode && number > 7}
                                               isStrong={strongMode && number <= 7} tablesData={tablesData}
                                               addNumber={addNumber} addStrongNumber={addStrongNumber}
                                               removeNumber={removeNumber} removeStrongNumber={removeStrongNumber}
                                               formType={formType}/>
                    })
                }
            </Flex>
            <Flex direction={'row-reverse'} display={['none', 'none', 'none', 'flex']}>
                <Button width={150} disabled={disabledPrev} onClick={onPrevRow} backgroundColor={'#1E1E1E'}>שורה
                    קודמת</Button>
                <Button disabled={disabledNext} width={150} onClick={onNextRow}
                        backgroundColor={'#1E1E1E'}>שורה הבאה</Button>
            </Flex>
            <Flex justifyContent={'center'} display={['none', 'none', 'none', 'flex']}>
                <Text style={{cursor: 'pointer'}} onClick={onClearTable}>איפוס</Text>
            </Flex>
        </Flex>
    )
}

export default Keyboard
