import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import Keyboard from "../Keyboard";
import {Flex, Grid, useAuthenticator, useTheme, View} from "@aws-amplify/ui-react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    addNumber, addStrongNumber,
    automaticFillRow, clearRow,
    clearTable,
    FormTypes, lottoCostStatus,
    lottoMaxNumber,
    lottoMaxStrongNumber, regularLottoCost,
    regularLottoData, regularLottoFormSize,
    regularLottoFormType,
    regularLottoSendFormError,
    regularLottoSendFormStatus,
    regularLottoTables, removeNumber, removeStrongNumber,
    sendLotto,
    setFormType
} from "../../features/regularLotto/regularLottoSlice";
import {NavMenu} from "../navMenu";
import {Modal, useModal} from "../Modal/Modal";
import {LoadMoreCreditContent, ModalErrorContent} from "../Modal/ModalContent";
import {LottoFooter} from "./LottoFooter";
import {RegularLottoForm} from "./regularLottoForm";
import {getSelectedRow, getShowKeyboard, setSelectedRowIndex} from "../../features/keyboard/keyboardSlice";
import {getShowAlert, setAlertText, setAlertVariant, setShowAlert} from "../../features/app/appSlice";
import {AppAlert} from "../AppAlert";
import {useLocation, useNavigate} from "react-router-dom";

const RegularLottoContainer: React.FC<{ lottoType: 'regular' | 'system' | 'strong' }> = (props) => {
    const {lottoType} = props;
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const tablesData = useAppSelector(regularLottoTables);
    const formData = useAppSelector(regularLottoData);
    const sendFormStatus = useAppSelector(regularLottoSendFormStatus);
    const sendFormError = useAppSelector(regularLottoSendFormError);
    const showKeyboard = useAppSelector(getShowKeyboard);
    const {user} = useAuthenticator((context) => [context.user]);
    const {isShown, toggle} = useModal();
    const [showLoadCredit, setShowLoadCredit] = useState(false);
    const showAlert = useAppSelector(getShowAlert);
    const formType = useAppSelector(regularLottoFormType);
    const selectedRowIndex = useAppSelector(getSelectedRow);
    const maxSelectedNumbers = useAppSelector(lottoMaxNumber);
    const maxSelectedStrongNumbers = useAppSelector(lottoMaxStrongNumber);
    const getCostStatus = useAppSelector(lottoCostStatus);
    const cost = useAppSelector(regularLottoCost);
    const formSize = useAppSelector(regularLottoFormSize);
    const elRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        dispatch(clearTable())
        dispatch(setSelectedRowIndex(0));
        switch (lottoType) {
            case 'strong':
                dispatch(setFormType(FormTypes.STRONG_LOTTO));
                break;
            case 'system':
                dispatch(setFormType(FormTypes.SYSTEM_LOTTO))
                break;
            case 'regular':
            default:
                dispatch(setFormType(FormTypes.REGULAR))
                break;
        }
    }, [dispatch, lottoType])

    useEffect(() => {
        const offset = selectedRowIndex * 40
        elRef.current?.scroll({
            top: offset,
            behavior: 'smooth'
        })
    }, [elRef, selectedRowIndex])

    const modalContent = useMemo(() => {
        if (showLoadCredit) {
            return <LoadMoreCreditContent hide={toggle} setShowLoadCredit={setShowLoadCredit} formData={formData}/>
        } else if (sendFormStatus === 'failed') {
            return <ModalErrorContent hide={toggle} setShowLoadCredit={setShowLoadCredit}
                                      sendFormError={sendFormError}/>
        } else {
            return <>success !</>
        }
    }, [sendFormStatus, showLoadCredit, toggle])

    const modalHeader = useMemo(() => {
        if (showLoadCredit) {
            return 'טעינת קרדיט'
        } else if (sendFormStatus === 'failed') {
            return sendFormError.error
        } else {
            return <>success !</>
        }
    }, [sendFormError?.error, sendFormStatus, showLoadCredit])


    const filledRows = useMemo(() => {
        return tablesData.filter(row => row.numbers.length === maxSelectedNumbers && row.strong_number.length === maxSelectedStrongNumbers).length
    }, [maxSelectedNumbers, maxSelectedStrongNumbers, tablesData]);

    useEffect(() => {
        console.log(sendFormStatus)
        if (sendFormStatus === 'failed') {
            toggle()
        }
    }, [sendFormStatus])

    const onSendForm = useCallback(async () => {
        if (!user) {
            navigate('/login', {replace: true, state: {from: location}})
            return;
        }
        if (filledRows) {
            const authToken = user.getSignInUserSession()?.getAccessToken().getJwtToken();
            if (formType === FormTypes.REGULAR || formType === FormTypes.REGULAR_DOUBLE) {
                if (filledRows % 2 === 0) {
                    await dispatch(sendLotto({authToken, data: formData}))
                    dispatch(clearTable());
                } else {
                    dispatch(setShowAlert(true));
                    dispatch(setAlertVariant('error'));
                    dispatch(setAlertText('עליך למלא מספר זוגי של שורות'));
                }
            } else {
                dispatch(sendLotto({authToken, data: formData}))
            }
            dispatch(clearTable());
        } else {
            dispatch(setShowAlert(true));
            dispatch(setAlertVariant('error'));
            dispatch(setAlertText('עליך למלא את כל המספרים'));
        }
    }, [dispatch, formData, formType, user])

    const {tokens} = useTheme();

    return (
        <>
            {showAlert && <AppAlert/>}
            <Grid
                templateColumns={{base: '1fr', large: '2fr 2fr 1.5fr'}}
                templateRows={{base: 'repeat(3)', large: 'repeat(2)'}}
                gap={tokens.space.small}>
                <Flex order={[2, 2, 2, 1]} alignItems={'center'} justifyContent={'center'}>
                    {showKeyboard && <Keyboard maxSelectedNumbers={maxSelectedNumbers} numbersLength={37}
                                               maxSelectedStrongNumbers={maxSelectedStrongNumbers} formType={formType}
                                               automaticFillRow={automaticFillRow} clearRow={clearRow}
                                               clearTable={clearTable} formSize={formSize} tablesData={tablesData}
                                               addNumber={addNumber} addStrongNumber={addStrongNumber}
                                               removeStrongNumber={removeStrongNumber} removeNumber={removeNumber}/>}
                </Flex>
                <View order={[1, 1, 1, 2]}>
                    <RegularLottoForm elRef={elRef}/>
                </View>
                <Flex display={['none', 'none', 'none', 'flex']} justifyContent={'flex-end'} order={3}>
                    <NavMenu/>
                </Flex>
                <View order={3} columnSpan={[1, 1, 1, 3]}
                      style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}>
                    <LottoFooter filledRows={filledRows} onSendForm={onSendForm} sendFormStatus={sendFormStatus}
                                 cost={cost} getCostStatus={getCostStatus}/>
                </View>
                <Modal
                    isShown={isShown}
                    hide={toggle}
                    headerText={modalHeader}
                    modalContent={modalContent}
                />
            </Grid>
        </>
    )
}

export default RegularLottoContainer
