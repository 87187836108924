import React from "react";
import {Flex, Heading, Text} from "@aws-amplify/ui-react";
import {BsFacebook, BsInstagram, BsWhatsapp} from "react-icons/bs";
import {ABOUT_LOTTOMATIC_TEXT, ABOUT_LOTTOMATIC_TITLE} from "./descriptionConsts";

export const AboutUs: React.FC = () => {
    return <Flex justifyContent={'center'} direction={'column'} alignItems={'center'} padding={15}>
        <Heading level={3}>{ABOUT_LOTTOMATIC_TITLE}</Heading>
        <Text whiteSpace={'break-spaces'}>{ABOUT_LOTTOMATIC_TEXT}</Text>
    </Flex>
}
