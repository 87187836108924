import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {FormType} from "../../utils/types";
import {getForms, getPaymentsForUser, getReceiptForForm, getWiningForms} from "./userInfoApi";

export type GameStatus = 'pending' | 'printed' | 'scanned' | 'finished' | 'failed' | 'won' | 'lost' | 'archived';

export interface GameForm {
    id: string,
    agent: string,
    form_kind: FormType,
    lottery_number: number,
    main_game: string,
    name: string,
    origin_price: number,
    price: number,
    prize: number,
    status: GameStatus,
    time: string,
    user_name: string,
    transaction_id: string,
    scan_id: string,
    reseller_code: string,
    marks: any,
}

export type Operation =
    'deposit_no_create'
    | 'deposit_with_create'
    | 'create_only'
    | 'token_charge'
    | 'token_withdraw'
    | 'game_withdraw'
    | 'withdraw_with_create'
    | 'manual_charge'
    | 'win_deposit'

export type PaymentsStatus =
    'init'
    | 'success'
    | 'failure'
    | 'approved_await_token'
    | 'await_approval'
    | 'await_refund'
    | 'declined_await_token'
    | 'declined'

export interface Payment {
    date: any;
    id: string,
    userId: string,
    ccTokenId: string,
    operation: Operation,
    status: PaymentsStatus,
    sumAmount: number,
    coin: 'dollar' | 'shekel',
    created: string,
    updates: string,
}

export interface UserInfoState {
    forms: GameForm[];
    getUserFormsStatus: 'idle' | 'loading' | 'failed';
    receiptUrl: string;
    getReceiptStatus: 'idle' | 'loading' | 'failed';
    payments: Payment[];
    getPaymentsStatus: 'idle' | 'loading' | 'failed';
}

const initialState: UserInfoState = {
    forms: [],
    getUserFormsStatus: 'idle',
    receiptUrl: '',
    getReceiptStatus: 'idle',
    payments: [],
    getPaymentsStatus: 'idle',
};

export const getUserForms = createAsyncThunk(
    'userInfo/getUserForms',
    async (data: any, {rejectWithValue}) => {
        const response = data.won ? await getWiningForms(data.authToken) : await getForms(data.authToken);
        if (response.status !== 200) {
            return rejectWithValue(await response.json())
        }
        return await response.json();
    }
);

export const getReceipt = createAsyncThunk(
    'userInfo/getReceipt',
    async (data: any, {rejectWithValue}) => {
        const response = await getReceiptForForm(data.authToken, data.formId);
        if (response.status !== 200) {
            return rejectWithValue(await response.json())
        }
        return await response.json();
    }
);

export const getUserPaymentsHistory = createAsyncThunk(
    'userInfo/getPaymentsHistory',
    async (authToken: any, {rejectWithValue}) => {
        const response = await getPaymentsForUser(authToken);
        if (response.status !== 200) {
            return rejectWithValue(await response.json())
        }
        return await response.json();
    }
);

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getUserForms.pending, (state) => {
                state.getUserFormsStatus = 'loading';
            })
            .addCase(getUserForms.fulfilled, (state, action) => {
                state.getUserFormsStatus = 'idle';
                state.forms = action.payload;
            })
            .addCase(getUserForms.rejected, (state, action) => {
                state.getUserFormsStatus = 'failed';
            })
            .addCase(getReceipt.pending, (state) => {
                state.getReceiptStatus = 'loading';
            })
            .addCase(getReceipt.fulfilled, (state, action) => {
                state.getReceiptStatus = 'idle';
                state.receiptUrl = action.payload.file_url;
            })
            .addCase(getReceipt.rejected, (state, action) => {
                state.getReceiptStatus = 'failed';
            })
            .addCase(getUserPaymentsHistory.pending, (state) => {
                state.getPaymentsStatus = 'loading';
            })
            .addCase(getUserPaymentsHistory.fulfilled, (state, action) => {
                state.getPaymentsStatus = 'idle';
                state.payments = action.payload;
            })
            .addCase(getUserPaymentsHistory.rejected, (state, action) => {
                state.getPaymentsStatus = 'failed';
            })
    }
});

// export const {} = userInfoSlice.actions;

export const getUserGameForms = (state: RootState) => state.userInfo.forms;
export const getUserGameFormsStatus = (state: RootState) => state.userInfo.getUserFormsStatus;
export const getReceiptUrl = (state: RootState) => state.userInfo.receiptUrl;
export const getReceiptStatus = (state: RootState) => state.userInfo.getReceiptStatus;
export const getPayments = (state: RootState) => state.userInfo.payments;
export const getPaymentsStatus = (state: RootState) => state.userInfo.getPaymentsStatus;

export default userInfoSlice.reducer;
