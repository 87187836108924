import {
    Button,
    Divider,
    Expander,
    ExpanderItem,
    Flex,
    Loader,
    ScrollView,
    Text,
    useAuthenticator,
    Image,
} from "@aws-amplify/ui-react";
import {makeStyles} from "@material-ui/core/styles";
import React, {useCallback, useEffect, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    GameForm,
    getReceipt, getReceiptStatus, getReceiptUrl,
    getUserForms,
    getUserGameForms,
    getUserGameFormsStatus
} from "../../features/userInfo/userInfoSlice";
import {FormInfo} from "./FormInfo";
import {GiTrophyCup} from "react-icons/gi";
import moment from 'moment';
import {FaEye} from "react-icons/fa";
import {IoArrowUndoSharp} from "react-icons/io5";
import {Modal, useModal} from "../Modal/Modal";
import {chanceGames, lottoGames, tripleSevenGames} from "../../utils/types";
import {sendLotto} from "../../features/regularLotto/regularLottoSlice";
import {sendChance} from "../../features/chance/chanceSlice";
import {send777} from "../../features/tripleSeven/tripleSevenSlice";
import {send123} from "../../features/oneTwoThree/oneTwoThreeSlice";
import {getShowAlert} from "../../features/app/appSlice";
import {AppAlert} from "../AppAlert";

const useStyle = makeStyles(({
    ball: {
        width: 15,
        height: 15,
        borderRadius: 35,
    },
    ballFlex: {
        flexDirection: 'row-reverse',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5,
    },
    formExpander: {
        '& .amplify-expander__trigger, .amplify-expander__content': {
            paddingInline: 6,
        }
    },
}));

export const FormTitle: React.FC<{ form: GameForm }> = (props) => {
    const {form} = props;
    const classes = useStyle();
    const color = useMemo(() => {
        if (form.main_game === 'לוטו') {
            return '#DE3500';
        } else if (form.main_game === 'צ\'אנס') {
            return '#2AA100';
        } else if (form.main_game === '777') {
            return '#A1009B'
        } else if (form.main_game === '123') {
            return '#F98600'
        } else {
            return '#000000'
        }
    }, [form.main_game])

    const isActive = useMemo(() => form.status === 'scanned' || form.status === 'printed', [form.status]);
    const isDone = useMemo(() => ['finished', 'won', 'archived', 'failed'].includes(form.status), [form.status]);

    return <Flex direction={'column'} maxWidth={[300, 300, 300, 500]}>
        <Flex width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
            <Text opacity={isDone ? 0.2 : 1} color={color} fontSize={18}
                  fontWeight={500}>{form.name} - {moment(form.time).format('DD/MM/YYYY')}</Text>
            <Flex>
                {form.status === 'won' && <GiTrophyCup/>}
                <Flex className={classes.ball}
                      backgroundColor={isActive ? '#20D103' : isDone ? '#D10303' : '#ffffff'}
                      border={form.status === 'pending' ? '1px solid #20D103' : ''}/>
            </Flex>
        </Flex>
        <Flex>
            <Text color={'inherit'} whiteSpace={'nowrap'}>
                שולם:
                <Text paddingInline={12} color={'inherit'} fontWeight={'bold'} as={'span'}>{form.price} ש״ח</Text>
            </Text>
            <Text color={'inherit'} whiteSpace={'nowrap'} overflow={'hidden'} style={{textOverflow: 'ellipsis'}}>
                מספר הגרלה:
                <Text paddingInline={12} color={'inherit'} fontWeight={'bold'} as={'span'}>{form.lottery_number}</Text>
            </Text>
        </Flex>
    </Flex>
}

const UserFormsInfo: React.FC<{ userForms: GameForm[] }> = (props) => {
    const {userForms} = props;
    const {isShown, toggle} = useModal();
    const {user} = useAuthenticator((context) => [context.user]);
    const dispatch = useAppDispatch();
    const classes = useStyle();
    const receiptUrl = useAppSelector(getReceiptUrl)
    const receiptGetStatus = useAppSelector(getReceiptStatus)
    const receiptImage = useMemo(() => {
        return <Flex>
            {receiptGetStatus === 'loading' && <Loader size="large" variation="linear"/>}
            {receiptGetStatus === 'failed' && <Text>עוד אין קבלה לטופס זה</Text>}
            {receiptGetStatus === 'idle' && <Image
                style={{clipPath: 'inset(350px 0% 100px 0%)'}}
                marginTop={-350}
                src={receiptUrl}
                alt={'xx'}/>}
        </Flex>
    }, [receiptGetStatus, receiptUrl])

    const onWatchReceiptClick = useCallback((formId: string) => {
        const authToken = user.getSignInUserSession()?.getAccessToken().getJwtToken();
        dispatch(getReceipt({authToken, formId}))
        toggle()
    }, [dispatch, toggle, user])

    const onSendAgain = useCallback((form: GameForm) => {
        const authToken = user.getSignInUserSession()?.getAccessToken().getJwtToken();
        if (lottoGames.includes(form.form_kind)) {
            dispatch(sendLotto({authToken, data: form.marks}))
        } else if (chanceGames.includes(form.form_kind)) {
            dispatch(sendChance({authToken, data: form.marks}))
        } else if (tripleSevenGames.includes(form.form_kind)) {
            dispatch(send777({authToken, data: form.marks}))
        } else if (form.form_kind === '123') {
            dispatch(send123({authToken, data: form.marks}))
        }
    }, [dispatch, user])

    return <>
        <Text fontSize={21} color={'#000C47'} fontWeight={600}>סה״כ {userForms.length} טפסים בחשבנוך</Text>
        <Divider/>
        <ScrollView>
            <Expander type="single" isCollapsible={true} style={{direction: 'rtl'}}
                      className={classes.formExpander}>
                {
                    userForms.map((form, i) => {
                        return <div key={i}>
                            <ExpanderItem value={'' + i} title={<FormTitle form={form}/>}>
                                <FormInfo form={form}/>
                            </ExpanderItem>
                            <Flex opacity={form.status === 'finished' ? 0.2 : 1} paddingInline={[2, 2, 2, 24]}
                                  paddingBlock={10} alignItems={'center'}
                                  fontSize={16} color={'#000C47'}>
                                <Button color={'black'} padding={4} onClick={() => onWatchReceiptClick(form.id)}>
                                    <span style={{marginLeft: 10}}>צפה בקבלה</span><FaEye/>
                                </Button>
                                <Button color={'black'} padding={4} onClick={() => onSendAgain(form)}>
                                    <span style={{marginLeft: 10}}>שלח שוב</span><IoArrowUndoSharp/>
                                </Button>
                            </Flex>
                            <Divider/>
                        </div>
                    })
                }
            </Expander>
        </ScrollView>
        <Modal
            isShown={isShown}
            hide={toggle}
            headerText={'receipt'}
            modalContent={receiptImage}
        />
    </>
}

export const MyForms: React.FC<{ type?: string }> = (props) => {
    const classes = useStyle();
    const dispatch = useAppDispatch();
    const {user} = useAuthenticator((context) => [context.user]);
    const userForms = useAppSelector(getUserGameForms);
    const userFormsStatus = useAppSelector(getUserGameFormsStatus);
    const showAlert = useAppSelector(getShowAlert);

    useEffect(() => {
        const authToken = user.getSignInUserSession()?.getAccessToken().getJwtToken();
        if (authToken) {
            dispatch(getUserForms({authToken, won: props.type}));
        }
    }, [dispatch, props.type, user])

    return <>
        {showAlert && <AppAlert/>}
        <Flex direction={'column'} paddingInline={[5, 5, 5, 50]} paddingBlock={[10, 10, 10, 80]}
              justifyContent={'center'}>
            {
                userFormsStatus === 'loading' ? <Loader variation="linear"/> : <UserFormsInfo userForms={userForms}/>
            }
            {props.type !== 'wins' && <Flex className={classes.ballFlex} gap={19}>
                <Flex className={classes.ballFlex}>
                    <Flex className={classes.ball} backgroundColor={'#20D103'}/>
                    <Text>פעיל</Text>
                </Flex>
                <Flex className={classes.ballFlex}>
                    <Flex className={classes.ball} border={'1px solid #20D103'}/>
                    <Text>ממתין</Text>
                </Flex>
                <Flex className={classes.ballFlex}>
                    <Flex className={classes.ball} backgroundColor={'#D10303'}/>
                    <Text opacity={0.5}>הסתיים</Text>
                </Flex>
            </Flex>}
        </Flex>
    </>
}
