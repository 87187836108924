import {createAsyncThunk, createSlice, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import _ from "lodash";
import {CALCULATE_PRICE_123_PATH, calculatePrice, SEND_FORM_123_PATH, sendForm} from "../regularLotto/regullarLottoApi";

export interface RowItem123 {
    numbers: Record<number, number>
}

export interface AddNumberPayload {
    index: number;
    value: number;
}

export interface AutomaticFillPayload {
    index: number;
    numbersLength: number;
}

export enum OneTwoThreeFormTypes {
    REGULAR_123 = 'regular_123'
}

export type RequestStatus = 'idle' | 'loading' | 'failed';

export interface OneTwoThreeState {
    tables: RowItem123[];
    cost: number;
    multi_lottery: number;
    costStatus: RequestStatus;
    sendFormStatus: RequestStatus;
    maximumRowsAllowed: number;
    formType: OneTwoThreeFormTypes;
    formSize: number;
    maxSelectedNumbers: number;
    participant_amount: number;
    error?: any;
}

const initialNumbersState = {1: -1, 2: -1, 3: -1};

const initialState: OneTwoThreeState = {
    tables: Array.from(Array(5), (_) => ({numbers: initialNumbersState})),
    multi_lottery: -1,
    cost: 0,
    costStatus: 'idle',
    sendFormStatus: 'idle',
    maximumRowsAllowed: 7,
    formType: OneTwoThreeFormTypes.REGULAR_123,
    formSize: 5,
    maxSelectedNumbers: 3,
    participant_amount: 1,
};

function getPathByType(type: OneTwoThreeFormTypes): Record<'calculate' | 'send', URL> {
    switch (type) {
        case OneTwoThreeFormTypes.REGULAR_123:
        default:
            return {calculate: CALCULATE_PRICE_123_PATH, send: SEND_FORM_123_PATH};
    }
}

export const calculateGamePrice123 = createAsyncThunk(
    'oneTwoThree/calculatePrice',
    async (data: any) => {
        const path = getPathByType(data.formType).calculate;
        const response = await calculatePrice(data, path);
        // The value we return becomes the `fulfilled` action payload
        return await response.json();
    }
);

export const send123 = createAsyncThunk(
    'oneTwoThree/sendForm',
    async (data: any, {rejectWithValue}) => {
        const path = getPathByType(data.data.formType).send;
        const response = await sendForm(data, path);
        if (response.status !== 200) {
            return rejectWithValue(await response.json())
        }
        return await response.json();
    }
);

export const oneTwoThreeSlice = createSlice({
    name: 'oneTwoThree',
    initialState,
    reducers: {
        addNumber: (state, action: PayloadAction<AddNumberPayload>) => {
            for (const item in state.tables[action.payload.index].numbers) {
                console.log(item);
                if (state.tables[action.payload.index].numbers[item] === -1) {
                    state.tables[action.payload.index].numbers[item] = action.payload.value;
                    break;
                }
            }
        },
        removeNumber: (state, action: PayloadAction<AddNumberPayload>) => {
            // state.tables[action.payload.index].numbers = state.tables[action.payload.index].numbers.filter(n => n !== action.payload.value)
        },
        automaticFillRow: (state, action: PayloadAction<AutomaticFillPayload>) => {
            for (const item in state.tables[action.payload.index].numbers) {
                state.tables[action.payload.index].numbers[item] = _.random(0, 9)
            }
        },
        clearRow: (state, action: PayloadAction<number>) => {
            state.tables[action.payload].numbers = initialNumbersState;
        },
        clearTable: (state) => {
            for (const row of state.tables) {
                row.numbers = initialNumbersState;
            }
            state.cost = 0;
        },
        autoFillTable: (state, action: PayloadAction<AutomaticFillPayload>) => {
            for (let i = 0; i < state.formSize; i++) {
                for (const item in state.tables[i].numbers) {
                    state.tables[i].numbers[item] = _.random(0, 9)
                }
            }
        },
        setFormSize: (state, action: PayloadAction<number>) => {
            state.formSize = action.payload;
        },
        setAmount: (state, action: PayloadAction<number>) => {
            state.participant_amount = action.payload
        },
        // setFormType: (state, action: PayloadAction<OneTwoThreeFormTypes>) => {
        //     switch (action.payload) {
        //         case OneTwoThreeFormTypes.REGULAR_777:
        //             state.tables = Array.from(Array(3), (_, index) => ({
        //                 numbers: [],
        //                 strong_number: [],
        //                 table_number: index + 1
        //             }))
        //             state.formSize = 3;
        //             state.maximumRowsAllowed = 3;
        //             state.maxSelectedNumbers = 7;
        //             break;
        //         case OneTwoThreeFormTypes.SHITATI_777:
        //             state.tables = Array.from(Array(1), (_, index) => ({
        //                 numbers: [],
        //                 strong_number: [],
        //                 table_number: index + 1
        //             }))
        //             state.formSize = 1;
        //             state.maximumRowsAllowed = 1;
        //             state.maxSelectedNumbers = 8;
        //             state.form_type = 8
        //             break;
        //     }
        //     state.formType = action.payload;
        // },
        // setMaxNumber: (state, action: PayloadAction<number>) => {
        //     state.maxSelectedNumbers = Number(action.payload);
        //     state.form_type = Number(action.payload);
        // },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                isAnyOf(calculateGamePrice123.pending), (state) => {
                    state.costStatus = 'loading';
                })
            .addMatcher(
                isAnyOf(calculateGamePrice123.fulfilled), (state, action) => {
                    state.costStatus = 'idle';
                    state.cost = action.payload['price'];
                }
            )
            .addMatcher(
                isAnyOf(calculateGamePrice123.rejected), (state) => {
                    state.costStatus = 'failed';
                }
            )
            .addMatcher(
                isAnyOf(send123.pending), (state) => {
                    state.sendFormStatus = 'loading';
                }
            )
            .addMatcher(
                isAnyOf(send123.fulfilled), (state, action) => {
                    state.sendFormStatus = 'idle';
                    // state.cost = action.payload['price'];
                }
            )
            .addMatcher(
                isAnyOf(send123.rejected), (state, action) => {
                    state.sendFormStatus = 'failed';
                    state.error = action.payload;
                }
            )
    },
});

export const {
    addNumber,
    removeNumber,
    automaticFillRow,
    clearRow,
    setFormSize,
    // setFormType,
    clearTable,
    autoFillTable,
    setAmount,
} = oneTwoThreeSlice.actions;
export const oneTwoThreeData = (state: RootState) => state.oneTwoThree;
export const oneTwoThreeTables = (state: RootState) => state.oneTwoThree.tables;
export const oneTwoThreeCost = (state: RootState) => state.oneTwoThree.cost;
export const oneTwoThreeCostStatus = (state: RootState) => state.oneTwoThree.costStatus;
export const oneTwoThreeSendFormStatus = (state: RootState) => state.oneTwoThree.sendFormStatus;
export const oneTwoThreeSendFormError = (state: RootState) => state.oneTwoThree.error;
export const oneTwoThreeFormSize = (state: RootState) => state.oneTwoThree.formSize;
export const oneTwoThreeMaximumRowsAllowed = (state: RootState) => state.oneTwoThree.maximumRowsAllowed;
export const oneTwoThreeFormType = (state: RootState) => state.oneTwoThree.formType;
export const oneTwoThreeMaxNumber = (state: RootState) => state.oneTwoThree.maxSelectedNumbers;
export const oneTwoThreeParticipantAmount = (state: RootState) => state.oneTwoThree.participant_amount;

export default oneTwoThreeSlice.reducer;
