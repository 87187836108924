import React, {Ref, useCallback, useEffect, useMemo} from "react";
import {Button, Flex, ScrollView, SelectField, Text} from "@aws-amplify/ui-react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {makeStyles} from "@material-ui/core/styles";
import {getSelectedRow, getShowKeyboard, setSelectedRowIndex} from "../../features/keyboard/keyboardSlice";
import {
    autoFillTable,
    calculateGamePrice777,
    clearTable,
    setFormSize,
    setMaxNumber,
    tripleSevenData,
    tripleSevenFormSize,
    tripleSevenFormType,
    TripleSevenFormTypes,
    tripleSevenMaxNumber,
    tripleSevenTables
} from "../../features/tripleSeven/tripleSevenSlice";
import {TripleSevenRow} from "./tripleSevenRow";

interface RegularLottoFormProps {
    elRef: Ref<HTMLDivElement>
}

const useStyle = makeStyles(({
    wrapper: {
        direction: 'rtl',
        justifyContent: 'center',
        '& .amplify-select__icon-wrapper': {
            right: 325,
        },
        '& .amplify-select': {
            borderRadius: 11,
            with: 400,
        }
    }
}))

export const TripleSevenForm: React.FC<RegularLottoFormProps> = (props) => {
    const dispatch = useAppDispatch();
    const classes = useStyle();
    const formData = useAppSelector(tripleSevenData);
    const tablesData = useAppSelector(tripleSevenTables);
    const formSize = useAppSelector(tripleSevenFormSize);
    const showKeyboard = useAppSelector(getShowKeyboard);
    const selectedRowIndex = useAppSelector(getSelectedRow);
    const formType = useAppSelector(tripleSevenFormType);
    const maxSelectedNumbers = useAppSelector(tripleSevenMaxNumber);

    const filledRows = useMemo(() => tablesData.filter(row => row.numbers.length === maxSelectedNumbers).length, [maxSelectedNumbers, tablesData])

    useEffect(() => {
        if (filledRows) {
            dispatch(calculateGamePrice777(formData))
        }
    }, [dispatch, filledRows, formType])


    const automaticFill = useCallback(() => {
        dispatch(autoFillTable({
            index: selectedRowIndex,
            numbersLength: maxSelectedNumbers,
        }))
    }, [dispatch, formSize, maxSelectedNumbers, selectedRowIndex])


    const onChangeRowNum = useCallback((e: any) => {
        dispatch(clearTable())
        dispatch(setSelectedRowIndex(0));
        if (formType === TripleSevenFormTypes.REGULAR_777) {
            dispatch(setFormSize(Number(e.currentTarget.value)))
            dispatch(setSelectedRowIndex(0));
        } else if (formType === TripleSevenFormTypes.SHITATI_777) {
            dispatch(setMaxNumber(e.currentTarget.value));
        }
    }, [dispatch, formType])

    const options = useMemo(() => {
        if (formType === TripleSevenFormTypes.REGULAR_777) {
            const numOfOptions = [1, 2, 3];
            return numOfOptions.map((_, index) => {
                return <option value={index + 1} key={index}>
                    {index + 1} שורות
                </option>
            })
        } else if (formType === TripleSevenFormTypes.SHITATI_777) {
            const numOfOptions = [8, 9]
            return numOfOptions.map((val) => {
                return <option value={val} key={val}>
                    שיטתי {val}
                </option>
            })
        }
    }, [formType])

    return (
        <Flex direction={'column'} paddingTop={[0, 0, 0, 40]} maxWidth={360} margin={'auto'}>
            <Text>* בחרו את מספר השורות וסוג הטופס -</Text>
            <Flex className={classes.wrapper}>
                <SelectField width={'100%'} label={false} fontWeight={'bold'} onChange={onChangeRowNum}
                             defaultValue={'' + formSize} style={{borderColor: '#FE50A1'}}>
                    {options}
                </SelectField>
            </Flex>
            <Button onClick={() => automaticFill()} backgroundColor={'#1E1E1E'} isFullWidth>מלא
                לוטומטיק</Button>
            <Text>*בחרו שורה וסמנו בעזרת המקלדת -</Text>
            <ScrollView ref={props.elRef} width="100%"
                        height={showKeyboard ? ['120px', '120px', '120px', "538px"] : 450}>
                {
                    Array.from(Array(formSize)).map((_, index) =>
                        <TripleSevenRow key={index} index={index}/>
                    )
                }
            </ScrollView>
        </Flex>
    )
}
