import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {
    Button,
    Flex,
    Placeholder, TabItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Tabs,
    useAuthenticator
} from "@aws-amplify/ui-react";
import {getPayments, getPaymentsStatus, getUserPaymentsHistory, Payment} from "../features/userInfo/userInfoSlice";
import {LoadMoreCreditContent, PullMoreCreditContent} from "./Modal/ModalContent";
import {Modal, useModal} from "./Modal/Modal";
import {AppAlert} from "./AppAlert";
import {getShowAlert} from "../features/app/appSlice";

const chargeStatuses = ['deposit_no_create', 'deposit_with_create', 'create_only', 'token_charge', 'manual_charge', 'win_deposit']
const withdrawStatuses = ['game_withdraw', 'withdraw_with_create']
const refundStatuses = ['token_withdraw']

export const PaymentsHistoryTable: React.FC<{ pType: 'refund' | 'charge' | 'withdraw' }> = (props) => {
    const payments = useAppSelector(getPayments);
    const paymentsRequestStatus = useAppSelector(getPaymentsStatus);

    const filteredPayments = useMemo(() => {
        if (props.pType === "withdraw") {
            return payments.filter(p => withdrawStatuses.includes(p.operation))
        } else if (props.pType === "charge") {
            return payments.filter(p => chargeStatuses.includes(p.operation))
        } else if (props.pType === "refund") {
            return payments.filter(p => refundStatuses.includes(p.operation))
        } else return payments
    }, [payments, props.pType])

    return paymentsRequestStatus === 'loading' ?
        <Flex direction={'column'} padding={24} style={{direction: 'rtl'}}>
            <Placeholder/>
            <Placeholder/>
            <Placeholder width={'50%'}/>
        </Flex> :
        <Table caption="" highlightOnHover style={{direction: 'rtl'}} size="small" variation="striped"
               textAlign={'center'}>
            <TableHead>
                <TableRow>
                    <TableCell as="th">מספר תשלום</TableCell>
                    {/*<TableCell as="th">סוג פעולה</TableCell>*/}
                    <TableCell as="th">תאריך</TableCell>
                    <TableCell as="th">סה״כ</TableCell>
                    <TableCell as="th">סטטוס</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    filteredPayments.map((result: Payment) => {
                        return <TableRow key={result.id}>
                            <TableCell>{result.id}</TableCell>
                            {/*<TableCell>{result.operation}</TableCell>*/}
                            <TableCell>{result.date}</TableCell>
                            <TableCell>{result.sumAmount}</TableCell>
                            <TableCell>{result.status}</TableCell>
                        </TableRow>
                    })
                }
            </TableBody>
        </Table>
}

export const PaymentsHistory: React.FC = () => {
    const {user} = useAuthenticator((context) => [context.user]);
    const dispatch = useAppDispatch();
    const {isShown, toggle} = useModal();
    const [showLoadCredit, setShowLoadCredit] = useState(false);
    const showAlert = useAppSelector(getShowAlert);

    useEffect(() => {
        const authToken = user.getSignInUserSession()?.getAccessToken().getJwtToken();
        dispatch(getUserPaymentsHistory(authToken))
    }, [dispatch])

    const onLoadCreditClick = useCallback(() => {
        setShowLoadCredit(true);
        toggle()
    }, [toggle])

    const onPullCreditClick = useCallback(() => {
        setShowLoadCredit(false);
        toggle()
    }, [toggle])

    const modalHeader = useMemo(() => showLoadCredit ? 'טעינת קרדיט' : 'משיכת קרדיט', [showLoadCredit])
    const modalContent = useMemo(() => showLoadCredit ? <LoadMoreCreditContent hide={toggle}/> :
        <PullMoreCreditContent hide={toggle}/>, [showLoadCredit, toggle]);

    return <>
        {showAlert && <AppAlert/>}
        <Flex direction={'column'} padding={[4, 4, 4, 24]}>
            <Flex direction={'row-reverse'}>
                <Button variation={'primary'} onClick={onLoadCreditClick}>טען קרדיט</Button>
                <Button variation={'primary'} onClick={onPullCreditClick}>משך קרדיט</Button>
            </Flex>
            <Tabs spacing="equal" style={{direction: 'rtl'}}>
                <TabItem title="הפקדות">
                    <PaymentsHistoryTable pType={'charge'}/>
                </TabItem>
                <TabItem title="משיכות">
                    <PaymentsHistoryTable pType={'withdraw'}/>
                </TabItem>
                <TabItem title="החזרים">
                    <PaymentsHistoryTable pType={'refund'}/>
                </TabItem>
            </Tabs>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={modalHeader}
                modalContent={modalContent}
            />
        </Flex>
    </>
}
