import React from "react";
import {Flex, Grid, Heading, Text, useTheme} from "@aws-amplify/ui-react";
import {Link} from "react-router-dom";
import {NavMenu} from "../navMenu";
import {makeStyles} from "@material-ui/core/styles";
import {CHANCE_TEXT} from "../../utils/texts";

const useStyle = makeStyles(({
    buttonContainer: {
        width: '100%',
        backgroundColor: '#40BC4C',
        justifyContent: 'center',
        alignItems: 'center',
        height: 100,
        borderRadius: 6,
        textShadow: '1px 1px 2px',
        fontSize: 20,
        fontWeight: 900,
    }
}));

export const ChanceIndex: React.FC = () => {
    const classes = useStyle();
    const {tokens} = useTheme();
    return <Grid
        templateColumns={{base: '1fr', large: '1fr 4fr 1.5fr'}}
        templateRows={{base: 'repeat(3)', large: 'repeat(2)'}}
        gap={tokens.space.small}>
        <Flex/>
        <Flex direction={'column'} paddingInline={4} paddingTop={[4, 4, 4, 62]}>
            <Link to={'/regular-chance'} style={{textDecoration: 'none'}}>
                <Flex className={classes.buttonContainer}>
                    <Text color={'rgba(255, 255, 255, 1)'}>צ׳אנס רגיל</Text>
                </Flex>
            </Link>
            <Link to={'/rav-chance'} style={{textDecoration: 'none'}}>
                <Flex className={classes.buttonContainer}>
                    <Text color={'rgba(255, 255, 255, 1)'}>רב צ׳אנס</Text>
                </Flex>
            </Link>
            <Link to={'/chance-shitati'} style={{textDecoration: 'none'}}>
                <Flex className={classes.buttonContainer}>
                    <Text color={'rgba(255, 255, 255, 1)'}>צ׳אנס שיטתי</Text>
                </Flex>
            </Link>
            <Flex direction={'column'} style={{direction: 'rtl'}} paddingTop={40}>
                <Heading>
                    הסבר על הגרלות צ'אנס
                </Heading>
                <Text color={'#868686'}>
                    {CHANCE_TEXT}
                </Text>
            </Flex>
        </Flex>
        <Flex display={['none', 'none', 'none', 'flex']} justifyContent={'flex-end'}>
            <NavMenu/>
        </Flex>
    </Grid>
}
