import React, {useMemo} from "react";
import {useAppSelector} from "../../app/hooks";
import {
    FormTypes,
    regularLottoFormType,
    regularLottoTables,
    RowItem
} from "../../features/regularLotto/regularLottoSlice";
import {Flex, Text} from "@aws-amplify/ui-react";
import {RegularLottoRow} from "./regullarLottoRow";
import {getSelectedRow} from "../../features/keyboard/keyboardSlice";

interface TableDoubleRowProps {
    index: number;
}

export function isRowFilled(tablesData: RowItem[], index: number): boolean {
    return tablesData[index] && tablesData[index].numbers.length === 6 && tablesData[index].strong_number.length === 1
}

export const TableDoubleRow: React.FC<TableDoubleRowProps> = (props) => {
    const {index} = props
    const tablesData = useAppSelector(regularLottoTables);
    const selectedRowIndex = useAppSelector(getSelectedRow);
    const formType = useAppSelector(regularLottoFormType);

    const isBothRowsFilled = useMemo(() => {
        return (isRowFilled(tablesData, index * 2) && isRowFilled(tablesData, index * 2 + 1));

    }, [index, tablesData])

    const isSelected = useMemo(() => selectedRowIndex === index * 2 || selectedRowIndex === index * 2 + 1, [selectedRowIndex, index])

    const bgColor = useMemo(() => {
        return isSelected ? "#FFD9D9" : isBothRowsFilled ? "#F2F2F2" : '';
    }, [isSelected, isBothRowsFilled])

    const indexColor = useMemo(() => {
        return isSelected ? "#000C47" : isBothRowsFilled ? "#FF1A1A" : '';
    }, [isSelected, isBothRowsFilled])

    return <Flex direction={'row'} alignItems={'center'} justifyContent={'center'}>
        <Flex direction={'column'} backgroundColor={bgColor} padding={8}
              borderRadius={18} marginBottom={11}>
            <RegularLottoRow key={index * 2} index={index * 2}/>
            {[FormTypes.REGULAR, FormTypes.REGULAR_DOUBLE].includes(formType) &&
            <RegularLottoRow key={(index * 2) + 1} index={(index * 2) + 1}/>}
        </Flex>
        {[FormTypes.REGULAR, FormTypes.REGULAR_DOUBLE].includes(formType) &&
        <Flex direction={'column'} gap={0} fontWeight={500} fontSize={14}
              opacity={isBothRowsFilled || isSelected ? '1' : '0.2'} display={['none', 'none', 'none', 'flex']}>
            <Text color={indexColor}>X</Text>
            <Text color={indexColor}>{(index * 2) + 2}</Text>
        </Flex>}
    </Flex>
}
