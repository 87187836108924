export type FormType =
    'regular_lotto' | 'regular_double_lotto' | 'double_lotto_shitati' | 'double_lotto_shitati_hazak'
    | 'lotto_shitati'
    | 'lotto_shitati_hazak'
    | 'regular_chance'
    | 'rav_chance'
    | 'chance_shitati' | 'regular_777' | "shitati_777" | "123"

export const lottoGames = ['regular_lotto', 'regular_double_lotto', 'double_lotto_shitati', 'double_lotto_shitati_hazak']
export const chanceGames = ['regular_chance', 'rav_chance']
export const tripleSevenGames = ['regular_777', 'shitati_777']

export const EnglishHebrewFormTypeMapping = {
    'regular_lotto': 'לוטו רגיל',
    'regular_double_lotto': 'דאבל לוטו רגיל',
    'double_lotto_shitati': 'דאבל לוטו שיטתי',
    'double_lotto_shitati_hazak': 'דאבל לוטו שיטתי חזק',
    'lotto_shitati': 'לוטו שיטתי',
    'lotto_shitati_hazak': 'לוטו שיטתי חזק',
    'regular_chance': "צ'אנס רגיל",
    'rav_chance': "רב צ'אנס",
    'chance_shitati': "צ'אנס שיטתי",
    'regular_777': '777 רגיל',
    "shitati_777": '777 שיטתי',
    "123": "הגרלת 123"
}

export type ResultStatus = 'success' | 'error' | 'warning' | undefined;
