import React, {useCallback, useEffect, useState} from 'react';
import './App.css';
import {Header} from './components/Header';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Footer} from './components/Footer';
import {DashboardCom} from './components/Dashboard';
import {ThemeProvider, Authenticator, Flex} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import LottoIndex from "./components/lottoIndex";
import RegularLottoContainer from "./components/regularLotto/regularLottoContainer";
import {UserInfoContainer} from "./components/userInfo/UserInfoContainer";
import {MyForms} from "./components/userInfo/myForms";
import {TripleSevenIndex} from "./components/tripleSeven/TripleSevenIndex";
import TripleSevenContainer from "./components/tripleSeven/trtipleSevenContainer";
import OneTwoThreeContainer from "./components/oneTwoThree/oneTwoThreeContainer";
import ChanceContainer from "./components/chance/ChanceContainer";
import {ChanceIndex} from "./components/chance/ChanceIndex";
import {Results} from "./features/results/Results";
import {PaymentsHistory} from "./components/PaymentsHistory";
import {theme} from "./theme";
import {RequireAuth} from "./components/RequireAuth";
import {Login} from "./components/login";
import {Modal, useModal} from "./components/Modal/Modal";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {getAlertVariant, getShowAlert, setAlertVariant, setShowAlert} from "./features/app/appSlice";
import {GiCheckMark} from "react-icons/gi";
import {GetInContact} from "./components/generalInfo/getInContact";
import {AboutUs} from "./components/generalInfo/aboutUs";
import {Terms} from "./components/generalInfo/privicyPolicy";
import {Articles} from "./components/generalInfo/articles";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

function App() {
    const dispatch = useAppDispatch();
    const {isShown, toggle} = useModal();
    const variant = useAppSelector(getAlertVariant);
    const showAlert = useAppSelector(getShowAlert);
    const [token, setToken] = useState<string>();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const onVerify = useCallback((_token: string) => {
        setToken(_token);
        console.log('Verified and token is', token);
    }, []);
    useEffect(() => {
        if (variant === 'success') {
            toggle()
            dispatch(setAlertVariant(undefined));
            dispatch(setShowAlert(false));
        }
    }, [showAlert, variant])
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6Ld_1K8mAAAAAJ9--nvPkQfv1be4As13B4CFkSyv">
            <GoogleReCaptcha
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
            />
            <ThemeProvider theme={theme}>
                <Authenticator.Provider>
                    <BrowserRouter>
                        <Header/>
                        <Routes>
                            <Route path="/" element={<DashboardCom/>}/>
                            <Route path="/results" element={<Results/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/contact-us" element={<GetInContact/>}/>
                            <Route path="/about-us" element={<AboutUs/>}/>
                            <Route path="/terms-of-use" element={<Terms/>}/>
                            <Route path="/articles" element={<Articles/>}/>
                            <Route path="/lotto-index" element={<LottoIndex/>}/>
                            <Route path="/777-index" element={<TripleSevenIndex/>}/>
                            <Route path="/regular-777" element={<TripleSevenContainer gameType={'regular'}/>}/>
                            <Route path="/shitati-777" element={<TripleSevenContainer gameType={'system'}/>}/>
                            <Route path="/chance-index" element={<ChanceIndex/>}/>
                            <Route path="/regular-chance" element={<ChanceContainer gameType={'regular'}/>}/>
                            <Route path="/rav-chance" element={<ChanceContainer gameType={'rav'}/>}/>
                            <Route path="/chance-shitati" element={<ChanceContainer gameType={'shitati'}/>}/>
                            <Route path="/123" element={<OneTwoThreeContainer/>}/>
                            <Route path="/regular-lotto" element={<RegularLottoContainer lottoType={'regular'}/>}/>
                            <Route path="/system-lotto" element={<RegularLottoContainer lottoType={'system'}/>}/>
                            <Route path="/strong-lotto" element={<RegularLottoContainer lottoType={'strong'}/>}/>
                            <Route path="/user-info" element={<RequireAuth><UserInfoContainer/></RequireAuth>}/>
                            <Route path="/my-forms" element={<RequireAuth><MyForms/></RequireAuth>}/>
                            <Route path="/my-wins" element={<RequireAuth><MyForms type={'wins'}/></RequireAuth>}/>
                            <Route path={'/payments-history'} element={<RequireAuth><PaymentsHistory/></RequireAuth>}/>
                        </Routes>
                        <Footer/>
                        <Modal
                            isShown={isShown}
                            hide={toggle}
                            headerText={'הטופס נשלח בהצלחה'}
                            modalContent={<Flex justifyContent={'center'}><GiCheckMark color={'#4BB543'}
                                                                                       fontSize={40}/></Flex>}
                        />
                    </BrowserRouter>
                </Authenticator.Provider>
            </ThemeProvider>
        </GoogleReCaptchaProvider>
    );
}

export default App;
