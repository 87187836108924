import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import Keyboard from "../Keyboard";
import {Alert, Flex, Grid, Heading, Text, useAuthenticator, useTheme, View} from "@aws-amplify/ui-react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {NavMenu} from "../navMenu";
import {Modal, useModal} from "../Modal/Modal";
import {LoadMoreCreditContent, ModalErrorContent} from "../Modal/ModalContent";
import {getShowKeyboard} from "../../features/keyboard/keyboardSlice";
import {
    getShowAlert,
    setAlertText,
    setAlertVariant,
    setShowAlert
} from "../../features/app/appSlice";
import {LottoFooter} from "../regularLotto/LottoFooter";
import {OneTwoThreeForm} from "./oneTwoThreeForm";
import {
    addNumber,
    automaticFillRow, clearRow,
    clearTable,
    oneTwoThreeCost, oneTwoThreeCostStatus,
    oneTwoThreeData, oneTwoThreeFormSize, oneTwoThreeFormType, oneTwoThreeMaxNumber, oneTwoThreeSendFormError,
    oneTwoThreeSendFormStatus,
    oneTwoThreeTables, removeNumber, send123
} from "../../features/oneTwoThree/oneTwoThreeSlice";
import {AppAlert} from "../AppAlert";
import {useLocation, useNavigate} from "react-router-dom";
import {TEXT_123} from "../../utils/texts";

const OneTwoThreeContainer: React.FC = (props) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const tablesData = useAppSelector(oneTwoThreeTables);
    const formData = useAppSelector(oneTwoThreeData);
    const sendFormStatus = useAppSelector(oneTwoThreeSendFormStatus);
    const sendFormError = useAppSelector(oneTwoThreeSendFormError);
    const showKeyboard = useAppSelector(getShowKeyboard);
    const {user} = useAuthenticator((context) => [context.user]);
    const {isShown, toggle} = useModal();
    const [showLoadCredit, setShowLoadCredit] = useState(false);
    const showAlert = useAppSelector(getShowAlert);
    const formType = useAppSelector(oneTwoThreeFormType);
    const maxSelectedNumbers = useAppSelector(oneTwoThreeMaxNumber);
    const cost = useAppSelector(oneTwoThreeCost);
    const getCostStatus = useAppSelector(oneTwoThreeCostStatus);
    const formSize = useAppSelector(oneTwoThreeFormSize);
    const elRef = useRef<null | HTMLDivElement>(null);

    const modalContent = useMemo(() => {
        if (showLoadCredit) {
            return <LoadMoreCreditContent hide={toggle} setShowLoadCredit={setShowLoadCredit} formData={formData}/>
        } else if (sendFormStatus === 'failed') {
            return <ModalErrorContent hide={toggle} setShowLoadCredit={setShowLoadCredit}
                                      sendFormError={sendFormError}/>
        } else {
            return <>success !</>
        }
    }, [sendFormStatus, showLoadCredit, toggle])

    const modalHeader = useMemo(() => {
        if (showLoadCredit) {
            return 'טעינת קרדיט'
        } else if (sendFormStatus === 'failed') {
            return sendFormError.error;
        } else {
            return <>success !</>
        }
    }, [sendFormError?.error, sendFormStatus, showLoadCredit])


    const filledRows = useMemo(() => {
        return tablesData.filter(row => Object.values(row.numbers).every(item => item > -1)).length
    }, [maxSelectedNumbers, tablesData]);

    useEffect(() => {
        console.log(sendFormStatus)
        if (sendFormStatus === 'failed') {
            toggle()
        }
    }, [sendFormStatus])

    const onSendForm = useCallback(async () => {
        if (!user) {
            navigate('/login', {replace: true, state: {from: location}})
            return;
        }
        if (filledRows) {
            const authToken = user.getSignInUserSession()?.getAccessToken().getJwtToken();
            await dispatch(send123({authToken, data: formData}));
            dispatch(clearTable());
        } else {
            dispatch(setShowAlert(true));
            dispatch(setAlertVariant('error'));
            dispatch(setAlertText('עליך למלא את כל המספרים'));
        }
    }, [dispatch, formData, formType, user])

    const {tokens} = useTheme();

    return (
        <>
            {showAlert && <AppAlert/>}
            <Grid
                templateColumns={{base: '1fr', large: '2fr 2fr 1.5fr'}}
                templateRows={{base: 'repeat(3)', large: 'repeat(2)'}}
                gap={tokens.space.small}>
                <Flex order={[2, 2, 2, 1]} alignItems={'center'} justifyContent={'center'}>
                    {showKeyboard && <Keyboard maxSelectedNumbers={maxSelectedNumbers} numbersLength={10}
                                               maxSelectedStrongNumbers={0} formType={formType}
                                               automaticFillRow={automaticFillRow} clearRow={clearRow}
                                               clearTable={clearTable} formSize={formSize} tablesData={tablesData}
                                               addNumber={addNumber} removeNumber={removeNumber}/>}
                </Flex>
                <View order={[1, 1, 1, 2]}>
                    <OneTwoThreeForm elRef={elRef}/>
                </View>
                <Flex display={['none', 'none', 'none', 'flex']} justifyContent={'flex-end'} order={3}>
                    <NavMenu/>
                </Flex>
                <View order={3} columnSpan={[1, 1, 1, 3]}
                      style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}>
                    <LottoFooter filledRows={filledRows} onSendForm={onSendForm} sendFormStatus={sendFormStatus}
                                 cost={cost} getCostStatus={getCostStatus}/>
                </View>
                <Flex columnSpan={[1, 1, 1, 3]} order={4} direction={'column'} style={{direction: 'rtl'}}
                      paddingTop={40} paddingInline={24}>
                    <Heading>
                        הסבר על הגרלות 123
                    </Heading>
                    <Text color={'#868686'} padding={4}>
                        {TEXT_123}
                    </Text>
                </Flex>
                <Modal
                    isShown={isShown}
                    hide={toggle}
                    headerText={modalHeader}
                    modalContent={modalContent}
                />
            </Grid>
        </>
    )
}

export default OneTwoThreeContainer
