import React, {Ref, useCallback, useEffect, useMemo} from "react";
import {Button, Flex, ScrollView, SelectField, Text} from "@aws-amplify/ui-react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {makeStyles} from "@material-ui/core/styles";
import {getSelectedRow, getShowKeyboard} from "../../features/keyboard/keyboardSlice";

import {OneTwoThreeRow} from "./oneTwoThreeRow";
import {
    autoFillTable,
    calculateGamePrice123, clearTable,
    oneTwoThreeData,
    oneTwoThreeFormSize,
    oneTwoThreeFormType, oneTwoThreeMaxNumber, oneTwoThreeParticipantAmount,
    oneTwoThreeTables, setAmount
} from "../../features/oneTwoThree/oneTwoThreeSlice";

interface RegularLottoFormProps {
    elRef: Ref<HTMLDivElement>
}

const useStyle = makeStyles(({
    wrapper: {
        direction: 'rtl',
        justifyContent: 'center',
        '& .amplify-select__icon-wrapper': {
            right: 325,
        },
        '& .amplify-select': {
            borderRadius: 11,
            with: 400,
        }
    }
}))

export const OneTwoThreeForm: React.FC<RegularLottoFormProps> = (props) => {
    const dispatch = useAppDispatch();
    const classes = useStyle();
    const formData = useAppSelector(oneTwoThreeData);
    const tablesData = useAppSelector(oneTwoThreeTables);
    const formSize = useAppSelector(oneTwoThreeFormSize);
    const showKeyboard = useAppSelector(getShowKeyboard);
    const selectedRowIndex = useAppSelector(getSelectedRow);
    const formType = useAppSelector(oneTwoThreeFormType);
    const maxSelectedNumbers = useAppSelector(oneTwoThreeMaxNumber);
    const participantAmount = useAppSelector(oneTwoThreeParticipantAmount)

    const filledRows = useMemo(() => {
        return tablesData.filter(row => Object.values(row.numbers).every(item => item > -1)).length
    }, [tablesData]);

    useEffect(() => {
        if (filledRows) {
            dispatch(calculateGamePrice123(formData))
        }
    }, [dispatch, filledRows, formType])


    const automaticFill = useCallback(() => {
        dispatch(autoFillTable({
            index: selectedRowIndex,
            numbersLength: maxSelectedNumbers,
        }))
    }, [dispatch, formSize, maxSelectedNumbers, selectedRowIndex])

    useEffect(() => {
        dispatch(calculateGamePrice123(formData))
    }, [filledRows, participantAmount])


    const onChangeRowNum = useCallback((e: any) => {
        dispatch(setAmount(parseInt(e.currentTarget.value)))
    }, [dispatch])

    const options = useMemo(() => {
        const numOfOptions = [1, 2, 3, 5, 10, 25];
        return numOfOptions.map((val) => {
            return <option value={val} key={val}>
                {val}
            </option>
        })
    }, [])

    return (
        <Flex direction={'column'} paddingTop={[0, 0, 0, 40]} maxWidth={360} margin={'auto'}>
            <Text>* בחר את סכום ההשקעה -</Text>
            <Flex className={classes.wrapper}>
                <SelectField width={'100%'} label={false} fontWeight={'bold'} onChange={onChangeRowNum}
                             value={'' + participantAmount} style={{borderColor: '#FF8355'}}>
                    {options}
                </SelectField>
            </Flex>
            <Button onClick={() => automaticFill()} backgroundColor={'#1E1E1E'} isFullWidth>מלא
                לוטומטיק</Button>
            <Text>*בחרו שורה וסמנו בעזרת המקלדת -</Text>
            <ScrollView ref={props.elRef} width="100%"
                        height={showKeyboard ? ['120px', '120px', '120px', "538px"] : 450}>
                {
                    Array.from(Array(formSize)).map((_, index) =>
                        <OneTwoThreeRow key={index} index={index}/>
                    )
                }
            </ScrollView>
        </Flex>
    )
}
