import React from "react";
import {Button, Divider, Flex, Placeholder} from "@aws-amplify/ui-react";
import {RequestStatus} from "../../features/tripleSeven/tripleSevenSlice";

interface LottoFooterProps {
    filledRows: number;
    onSendForm: () => void;
    sendFormStatus: RequestStatus;
    getCostStatus: RequestStatus;
    cost: number;
}

export const LottoFooter: React.FC<LottoFooterProps> = (props) => {
    const {filledRows, onSendForm, sendFormStatus, getCostStatus, cost} = props
    return <>
        <Divider orientation="horizontal"/>
        <Flex justifyContent={'center'} alignItems={'center'} padding={12}>
            <Button isLoading={sendFormStatus === 'loading'} width={270} variation="primary"
                    onClick={onSendForm}>שליחת טופס</Button>
            <div>סה״כ {filledRows} צירופים -
                {getCostStatus === 'loading' ? <Placeholder/> :
                    <span style={{fontWeight: "bold"}}>לתשלום: {cost} ש״ח</span>}
            </div>
        </Flex>
    </>
}
