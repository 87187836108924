import {Badge, Divider, Flex, Text, useAuthenticator} from "@aws-amplify/ui-react";
import React, {useMemo} from "react";
import {useAppSelector} from "../../app/hooks";
import {getUserBalance} from "../../features/app/appSlice";
import {GrContactInfo, GrCopy, GrCreditCard} from "react-icons/gr";
import Avatar from "@mui/material/Avatar";
import {stringAvatar} from "../Header";
import {makeStyles} from "@material-ui/core/styles";
import {GiTrophyCup, GiMoneyStack} from "react-icons/gi";
import {AiOutlineClose} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import {Modal, useModal} from "../Modal/Modal";
import {LoadMoreCreditContent} from "../Modal/ModalContent";

const useStyle = makeStyles(({
    menuItem: {
        flexDirection: 'row-reverse',
        alignItems: 'center',
        gap: 104,
        justifyContent: 'space-between',
        paddingInline: 30,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'hsl(0, 75%, 85%)'
        }
    }
}))

export const UserInfoContainer: React.FC = () => {
    const classes = useStyle();
    const navigate = useNavigate();
    const {isShown, toggle} = useModal();
    const {user, signOut} = useAuthenticator((context) => [context.user]);
    const fullName = useMemo(() => `${user?.attributes?.name} ${user?.attributes?.family_name}`, [user]);
    const userBalance = useAppSelector(getUserBalance);
    return (
        <Flex direction={['column', 'column', 'column', 'row-reverse']} justifyContent={'center'}
              paddingBlock={[0, 0, 0, 150]} fontSize={20} gap={[4, 4, 4, 60]}>
            <Flex direction={'column'}>
                <Text fontWeight={'bold'} color={'#2D2C2C'} margin={'auto'} paddingTop={10}>{fullName}</Text>
                <Flex backgroundColor={'#000C47'} borderRadius={10} padding={4} margin={5} justifyContent={'center'}>
                    <Text color={'white'}>סה״כ יתרה בארנק: <span
                        style={{fontWeight: 'bold'}}>{userBalance} ש״ח</span></Text>
                </Flex>
                <Flex fontSize={16} justifyContent={'center'}>
                    <Text>{user?.attributes?.email}</Text>
                    <Divider height={'auto'} orientation="vertical"/>
                    <Text style={{direction: 'ltr'}}>{user?.attributes?.phone_number}</Text>
                </Flex>
                <Avatar {...stringAvatar(fullName, 150)} />
            </Flex>
            <Divider height={'auto'} orientation="vertical"/>
            <Flex as={'ul'} direction={'column'} gap={10}>
                <Divider opacity={0.2}/>
                <Flex as={'li'} className={classes.menuItem}>
                    <Text>עדכון פרטים אישיים</Text>
                    <GrContactInfo/>
                </Flex>
                <Divider opacity={0.2}/>
                <Flex as={'li'} className={classes.menuItem} onClick={toggle}>
                    <Text>הגדרות תשלום</Text>
                    <GrCreditCard/>
                </Flex>
                <Divider opacity={0.2}/>
                <Flex as={'li'} className={classes.menuItem} onClick={() => navigate('/payments-history')}>
                    <Text>הפקדות ומשיכות</Text>
                    <GiMoneyStack/>
                </Flex>
                <Divider opacity={0.2}/>
                <Flex as={'li'} className={classes.menuItem} onClick={() => navigate('/my-forms')}>
                    <Text>הטפסים שלי</Text>
                    <GrCopy/>
                </Flex>
                <Divider opacity={0.2}/>
                <Flex as={'li'} className={classes.menuItem} onClick={() => navigate('/my-wins')}>
                    <Text>סיכום זכיות</Text>
                    <GiTrophyCup/>
                </Flex>
                <Divider opacity={0.2}/>
                <Flex as={'li'} className={classes.menuItem} color={'#DF0000'} onClick={signOut}>
                    <Text color={'#DF0000'}>התנתקות</Text>
                    <AiOutlineClose/>
                </Flex>
                <Divider opacity={0.2}/>
            </Flex>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={'טעינת קרדיט'}
                modalContent={<LoadMoreCreditContent hide={toggle}/>}
            />
        </Flex>
    )
}
