import {SERVER_URL} from "../features/timer/timerAPI";
import {FormType, ResultStatus} from "./types";

// payment
export const CHARGE_CREDIT_PATH = new URL('/payments/charge', SERVER_URL)
export const CHARGE_CREDIT_TOKEN_PATH = new URL('/payments/charge-token', SERVER_URL)
export const HAS_CREDIT_TOKEN_PATH = new URL('/payments/has_token', SERVER_URL)
export const REFUND_TOKEN_PATH = new URL('/payments/refund-token', SERVER_URL)

export type HasTokenType = {
    token: boolean | string;
    end_digits?: string;
}
export type SuccessType = {
    result: ResultStatus,
    operation: string
}
export type ErrorType = {
    error: string,
    error_details: string
}

export const charge = async (jwtToken: string, amount: number, operation: number, lottomaticFormData?: string, formType?: FormType): Promise<string> => {
    let formData = new FormData();
    formData.append('sum', '' + amount);
    formData.append('product', 'טעינת קרדיט');
    formData.append('operation', '' + operation);
    if (lottomaticFormData && formType) {
        formData.append('lottomaticFormData', '' + lottomaticFormData);
        formData.append('formType', '' + formType);
    }
    return await fetch(CHARGE_CREDIT_PATH.href, {
        method: 'POST',
        headers: {
            Authorization: jwtToken
        },
        body: formData
    }).then(async (res) => (await res.json()).redirect)
};
export const charge_token = async (jwtToken: string, amount: number, lottomaticFormData?: string, formType?: FormType): Promise<SuccessType | ErrorType> => {
    let formData = new FormData();
    formData.append('sum', '' + amount);
    if (lottomaticFormData && formType) {
        formData.append('lottomaticFormData', '' + lottomaticFormData);
        formData.append('formType', '' + formType);
    }
    return await fetch(CHARGE_CREDIT_TOKEN_PATH.href, {
        method: 'POST',
        headers: {
            Authorization: jwtToken
        },
        body: formData
    }).then(res => res.json())
};

export const refund_token = async (jwtToken: string, amount: number): Promise<SuccessType | ErrorType> => {
    let formData = new FormData();
    formData.append('sum', '' + amount);
    return await fetch(REFUND_TOKEN_PATH.href, {
        method: 'POST',
        headers: {
            Authorization: jwtToken
        },
        body: formData
    }).then(res => res.json())
};

export const has_credit_token = async (jwtToken: string): Promise<HasTokenType> => {
    return await fetch(HAS_CREDIT_TOKEN_PATH.href, {
        headers: {
            Authorization: jwtToken
        }
    }).then(res => res.json())
};
