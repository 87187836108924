import {SERVER_URL} from "../timer/timerAPI";

export const GET_USER_FORMS_PATH = new URL('/my_space/user_forms', SERVER_URL)
export const GET_USER_WINS_FORMS_PATH = new URL('/my_space/user_forms/won', SERVER_URL)

export async function getForms(authToken: string): Promise<Response> {
    return await fetch(GET_USER_FORMS_PATH.href, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken
        },
    })
}

export async function getWiningForms(authToken: string): Promise<Response> {
    return await fetch(GET_USER_WINS_FORMS_PATH.href, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken
        },
    })
}

export async function getReceiptForForm(authToken: string, formId: string): Promise<Response> {
    let url = new URL(`/my_space/receipt/${formId}`, SERVER_URL).href;
    return await fetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken
        },
    })
}

export async function getPaymentsForUser(authToken: string): Promise<Response> {
    let url = new URL(`/my_space/all_payment_history`, SERVER_URL).href;
    return await fetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken
        },
    })
}
