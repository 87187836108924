import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    lottoMaxNumber,
    lottoMaxStrongNumber,
    regularLottoTables,
    RowItem
} from "../../features/regularLotto/regularLottoSlice";
import {Flex} from "@aws-amplify/ui-react";
import {isRowFilled} from "./TableDoubleRow";
import {
    getSelectedRow,
    setSelectedRowIndex,
    setShowKeyboard,
    setStrongMode
} from "../../features/keyboard/keyboardSlice";

interface RegularLottoRowProps {
    index: number;
}

export const BALL_SIZE = 33;

export const RegularLottoRow: React.FC<RegularLottoRowProps> = (props) => {
    const {index} = props;
    const dispatch = useAppDispatch();
    const tablesData = useAppSelector(regularLottoTables)
    const selectedRowIndex = useAppSelector(getSelectedRow);
    const maxSelectedNumbers = useAppSelector(lottoMaxNumber);
    const maxSelectedStrongNumbers = useAppSelector(lottoMaxStrongNumber);
    const [rowData, setRowData] = useState<RowItem>()

    const filled = isRowFilled(tablesData, index);


    const onRowClick = useCallback((val: boolean) => {
        dispatch(setStrongMode(val));
        dispatch(setSelectedRowIndex(index));
        dispatch(setShowKeyboard(true));
    }, [dispatch, index])

    useEffect(() => {
        setRowData(tablesData[index])
    }, [tablesData, index])

    return <Flex direction={'row-reverse'} gap={12} opacity={(filled || index === selectedRowIndex) ? '1' : '0.2'}
                 style={{cursor: 'pointer'}} wrap={'wrap'}>
        {
            Array.from(Array(maxSelectedStrongNumbers)).map((_, ballIndex) => {
                return <Flex justifyContent={'center'} alignItems={'center'} borderRadius={35}
                             backgroundColor={'#FF1A1A'} key={ballIndex}
                             width={BALL_SIZE} height={BALL_SIZE} onClick={() => onRowClick(true)}>
                    {typeof rowData?.strong_number[ballIndex] !== "undefined" ? rowData?.strong_number[ballIndex] : ''}
                </Flex>
            })
        }
        {
            Array.from(Array(maxSelectedNumbers)).map((_, ballIndex) => {
                return <Flex justifyContent={'center'} alignItems={'center'} borderRadius={35}
                             backgroundColor={'#ffffff'} key={ballIndex}
                             border={`${index === selectedRowIndex ? '2px' : '1px'} solid #FF1A1A`}
                             width={BALL_SIZE} height={BALL_SIZE} onClick={() => onRowClick(false)}>
                    {typeof rowData?.numbers[ballIndex] !== "undefined" ? rowData?.numbers[ballIndex] : ''}
                </Flex>
            })
        }
    </Flex>
}
