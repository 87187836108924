import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Flex, Grid, useAuthenticator, useTheme, View} from "@aws-amplify/ui-react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {NavMenu} from "../navMenu";
import {Modal, useModal} from "../Modal/Modal";
import {LoadMoreCreditContent, ModalErrorContent} from "../Modal/ModalContent";
import {setSelectedRowIndex} from "../../features/keyboard/keyboardSlice";
import {
    getShowAlert,
    setAlertText,
    setAlertVariant,
    setShowAlert
} from "../../features/app/appSlice";
import {LottoFooter} from "../regularLotto/LottoFooter";
import {ChanceForm} from "./ChanceForm";
import {
    chanceCards, chanceCost, chanceCostStatus,
    chanceData, ChanceFormTypes, chanceMaxNumber,
    chanceSendFormError,
    chanceSendFormStatus, clearTable, sendChance, setFormType
} from "../../features/chance/chanceSlice";
import {AppAlert} from "../AppAlert";
import {useLocation, useNavigate} from "react-router-dom";

const ChanceContainer: React.FC<{ gameType: 'regular' | 'rav' | 'shitati' }> = (props) => {
    const {gameType} = props;
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const tablesData = useAppSelector(chanceCards);
    const formData = useAppSelector(chanceData);
    const sendFormStatus = useAppSelector(chanceSendFormStatus);
    const sendFormError = useAppSelector(chanceSendFormError);
    const rowsToBeSelected = useAppSelector(chanceMaxNumber);
    const {user} = useAuthenticator((context) => [context.user]);
    const {isShown, toggle} = useModal();
    const [showLoadCredit, setShowLoadCredit] = useState(false);
    const showAlert = useAppSelector(getShowAlert);
    const cost = useAppSelector(chanceCost);
    const getCostStatus = useAppSelector(chanceCostStatus);
    const elRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        dispatch(clearTable())
        dispatch(setSelectedRowIndex(0));
        switch (gameType) {
            case 'rav':
                dispatch(setFormType(ChanceFormTypes.RAV_CHANCE))
                break;
            case 'shitati':
                dispatch(setFormType(ChanceFormTypes.CHANCE_SHITATI))
                break;
            case 'regular':
            default:
                dispatch(setFormType(ChanceFormTypes.REGULAR_CHNACE))
                break;
        }
    }, [dispatch, gameType])

    const modalContent = useMemo(() => {
        if (showLoadCredit) {
            return <LoadMoreCreditContent hide={toggle} setShowLoadCredit={setShowLoadCredit} formData={formData}/>
        } else if (sendFormStatus === 'failed') {
            return <ModalErrorContent hide={toggle} setShowLoadCredit={setShowLoadCredit}
                                      sendFormError={sendFormError}/>
        } else {
            return <>success !</>
        }
    }, [sendFormStatus, showLoadCredit, toggle])

    const modalHeader = useMemo(() => {
        if (showLoadCredit) {
            return 'טעינת קרדיט'
        } else if (sendFormStatus === 'failed') {
            return sendFormError?.error ?? 'Error';
        } else {
            return <>success !</>
        }
    }, [sendFormError?.error, sendFormStatus, showLoadCredit])


    const filledRows = useMemo(() => {
        return Object.values(tablesData).reduce((a: number, b: string[]) => a += +b.length, 0)
    }, [tablesData]);

    const filledUniqueRows = useMemo(() => {
        return Object.values(tablesData).filter(row => row.length > 0).length;
    }, [tablesData])

    useEffect(() => {
        console.log(sendFormStatus)
        if (sendFormStatus === 'failed') {
            toggle()
        }
    }, [sendFormStatus])

    const onSendForm = useCallback(async () => {
        if (!user) {
            navigate('/login', {replace: true, state: {from: location}})
            return;
        }
        if (filledUniqueRows) {
            if (filledUniqueRows !== rowsToBeSelected) {
                dispatch(setShowAlert(true));
                dispatch(setAlertVariant('error'));
                dispatch(setAlertText(`עליך למלא ${rowsToBeSelected} שורות`));
                return;
            }
            else {
                const authToken = user.getSignInUserSession()?.getAccessToken().getJwtToken();
                await dispatch(sendChance({authToken, data: formData}));
                dispatch(clearTable());
                dispatch(setSelectedRowIndex(0));
                dispatch(setShowAlert(false));
            }
        } else {
            dispatch(setShowAlert(true));
            dispatch(setAlertVariant('error'));
            dispatch(setAlertText('עליך למלא את כל המספרים'));
        }
    }, [dispatch, filledRows, formData, user])

    const {tokens} = useTheme();

    return (
        <>
            {showAlert && <AppAlert/>}
            <Grid
                templateColumns={{base: '1fr', large: '2fr 2fr 1.5fr'}}
                templateRows={{base: 'repeat(3)', large: 'repeat(2)'}}
                gap={tokens.space.small}>
                <Flex order={[2, 2, 2, 1]} alignItems={'center'} justifyContent={'center'}/>
                <View order={[1, 1, 1, 2]}>
                    <ChanceForm elRef={elRef}/>
                </View>
                <Flex display={['none', 'none', 'none', 'flex']} justifyContent={'flex-end'} order={3}>
                    <NavMenu/>
                </Flex>
                <View order={3} columnSpan={[1, 1, 1, 3]}
                      style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}>
                    <LottoFooter filledRows={filledRows} onSendForm={onSendForm} sendFormStatus={sendFormStatus}
                                 cost={cost} getCostStatus={getCostStatus}/>
                </View>
                <Modal
                    isShown={isShown}
                    hide={toggle}
                    headerText={modalHeader}
                    modalContent={modalContent}
                />
            </Grid>
        </>
    )
}

export default ChanceContainer
