import React from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {getAlertText, getAlertVariant, setShowAlert} from "../features/app/appSlice";
import {Alert} from "@aws-amplify/ui-react";

export const AppAlert: React.FC = () => {
    const dispatch = useAppDispatch();
    const variant = useAppSelector(getAlertVariant);
    const text = useAppSelector(getAlertText);
    return <Alert onDismiss={() => dispatch(setShowAlert(false))} style={{direction: 'rtl', position: 'sticky', top: 0}}
                  isDismissible={true}
                  variation={variant}>{text}</Alert>
}
