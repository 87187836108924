import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {nextLotteryData} from "./timerSlice";
import moment from "moment";

interface TimerProps {
    gameType: 'lotto' | 'pais' | 'tripleSeven' | 'oneTwoThree'
}

export const Timer: React.FC<TimerProps> = (props) => {
    const {gameType} = props;
    const allLotteryData = useAppSelector(nextLotteryData);
    const lottoData = allLotteryData[gameType];
    const [time, setTime] = useState(moment())
    const newTime = moment(lottoData.time)
    useEffect(() => {
        const clockInterval = setInterval(() => setTime(moment()), 1000)

        return () => clearInterval(clockInterval)
    })


    const duration = moment.duration(newTime.diff(time))
    const days = newTime > time ? duration.get('days') : 0;
    const hours = newTime > time ? duration.get('hours') : 0;
    const minutes = newTime > time ? duration.get('minutes') : 0;
    const seconds = newTime > time ? duration.get('seconds') : 0;

    return <div className="text-center flex-fill">
        {gameType === 'lotto' ? <span
                className=''>ההגרלה הבאה בסך - <strong>{parseInt(lottoData.win_amount ?? '0').toLocaleString()}</strong></span> :
            <div style={{direction: 'rtl', textAlign: 'right'}}>ההגרלה הבאה בעוד -</div>}
        <ul className="counter-list d-flex align-items-center justify-content-between mt-2">
            <li><span>{days}</span>ימים</li>
            <li><span>{hours}</span>שעות</li>
            <li><span>{minutes}</span>דקות</li>
            <li><span>{seconds}</span>שניות</li>
        </ul>
    </div>
}
