import React from "react";
import {Flex, Heading, Text} from "@aws-amplify/ui-react";
import {ARTICLE_TEXT, ARTICLES_TITLE} from "./descriptionConsts";

export const Articles: React.FC = () => {
    return <Flex justifyContent={'center'} direction={'column'} alignItems={'center'} padding={15}>
        <Heading level={3}>{ARTICLES_TITLE}</Heading>
        <Text whiteSpace={'break-spaces'}>{ARTICLE_TEXT}</Text>
    </Flex>
}
