import React from "react";
import {Flex} from "@aws-amplify/ui-react";

export const NavMenu: React.FC = () => {
    return (
        <Flex direction={'column'} alignItems={'flex-end'} maxWidth={360}>
            <div className="right_sidebar">
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-home-tab"
                                data-bs-toggle="pill" data-bs-target="#pills-home" type="button"
                                role="tab" aria-controls="pills-home" aria-selected="true"> כניסה
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-profile" type="button" role="tab"
                                aria-controls="pills-profile" aria-selected="false"> הרשמה
                        </button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                         aria-labelledby="pills-home-tab" tabIndex={-1}>
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#"> כל
                                    המשחקים </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#"> תוצאות </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#"> עזרה </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#"> אודות </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">מאמרים </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">צור קשר</a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                         aria-labelledby="pills-profile-tab" tabIndex={-1}>second tab data
                    </div>
                </div>
            </div>
            <div className="right_sidebar_brands">
                <div className="brand_img">
                    <img className="img-fluid logo"
                         src={process.env.PUBLIC_URL + "/assets/images/brands.svg"} alt="12"/>
                </div>
                <div className="brands_para">
                    <p className="sidebar_para_text">
                        אתר זה מעניק שירות שליחת טפסי הגרלה של מפעל הפיס ופועל כגוף עצמאית המכירה
                        אסורה למי שטרם שמלאו לו או לה 18 שנים.
                    </p>
                </div>
            </div>
        </Flex>
    )
}
