import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useAppDispatch} from "../../app/hooks";
import {
    Button,
    CheckboxField,
    Flex,
    Radio,
    RadioGroupField,
    Text,
    TextField,
    useAuthenticator
} from "@aws-amplify/ui-react";
import {
    charge,
    charge_token,
    ErrorType,
    has_credit_token,
    HasTokenType,
    refund_token,
    SuccessType
} from "../../utils/payment";
import {IframeDialog} from "../iframeDialog";
import {setAlertText, setAlertVariant, setShowAlert} from "../../features/app/appSlice";

interface ModalContentProps {
    hide: () => void;
}

interface ErrorModalProps extends ModalContentProps {
    setShowLoadCredit: (val: boolean) => void;
    sendFormError: any;
}

interface LoadMoreCreditContentProps extends ModalContentProps {
    setShowLoadCredit?: (val: boolean) => void;
    formData?: any;
}

export const PullMoreCreditContent: React.FC<ModalContentProps> = (props) => {
    const dispatch = useAppDispatch();
    const {user} = useAuthenticator((context) => [context.user]);
    const [sumToPull, setSumToPull] = useState(0);
    const [creditToken, setCreditToken] = useState<HasTokenType>({token: false});
    const [isLoading, setIsLoading] = useState(false);

    const jwtToken = useMemo(() => user.getSignInUserSession()?.getAccessToken().getJwtToken(), [user]);

    useEffect(() => {
        if (jwtToken) {
            has_credit_token(jwtToken).then(res => {
                if (res.token === 'not found') {
                    setCreditToken({token: false})
                } else {
                    setCreditToken(res)
                }
            })
        }
    }, [jwtToken])

    const onClick = useCallback(() => {
        if (sumToPull && jwtToken) {
            setIsLoading(true);
            refund_token(jwtToken, sumToPull).then((res) => {
                setIsLoading(false);
                dispatch(setShowAlert(true));
                if ((res as ErrorType).error) {
                    dispatch(setAlertVariant('error'))
                    dispatch(setAlertText((res as ErrorType).error_details))
                } else {
                    dispatch(setAlertVariant('success'));
                    dispatch(setAlertText('זיכוי בוצע בהצלחה, זיכוי מעל 50 שקלים צריך לקבל אישור מנהל'));
                }
                props.hide();
            })
        }
    }, [dispatch, jwtToken, props, sumToPull])

    return !creditToken.token ?
        <Text>ניתן למשוך קרדיט רק כאשר יש כרטיס אשראי שמור במערכת, ניתן לשמור כרטיס גם בעזרת חיוב של שקל</Text> :
        <Flex direction={'column'} style={{direction: 'rtl'}}>
            <TextField
                style={{direction: 'rtl', color: '#000C47'}}
                variation="quiet"
                descriptiveText={<Text as={'span'} color={'#000C47'} fontSize={18}>זיכוי כרטיס שמסתיים ב
                    - {creditToken.end_digits}</Text>}
                placeholder="00.00"
                label={<Text color={'#000C47'} fontSize={24}>הכנס את הסכום המבוקש למשיכה בשקלים - </Text>}
                errorMessage="There is an error"
                inputMode={'decimal'}
                value={sumToPull}
                onChange={(e: any) => setSumToPull(e.currentTarget.value)}
            />
            <Button isDisabled={!sumToPull} isLoading={isLoading} onClick={onClick} backgroundColor={'#1B1A1A'}>משך
                קרדיט</Button>
            <Text>* עמלת סליקה בסך 5.90 תנוקה מסכום ההחזר</Text>
        </Flex>
}

export const LoadMoreCreditContent: React.FC<LoadMoreCreditContentProps> = (props) => {
    const {setShowLoadCredit, formData} = props;
    const dispatch = useAppDispatch();
    const {user} = useAuthenticator((context) => [context.user]);
    const [sumToLoad, setSumToLoad] = useState(0)
    const [chargeCurrent, setChargeCurrent] = useState<'existing' | 'new'>('existing')
    const [creditToken, setCreditToken] = useState<HasTokenType>({token: false})
    const [saveToken, setSaveToken] = useState(false)
    const [iframeOpen, setIframeOpen] = useState(false);
    const [src, setSrc] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const jwtToken = useMemo(() => user.getSignInUserSession()?.getAccessToken().getJwtToken(), [user])

    useEffect(() => {
        if (jwtToken) {
            has_credit_token(jwtToken).then(res => {
                if (res.token === 'not found') {
                    setCreditToken({token: false})
                } else {
                    setCreditToken(res)
                    setChargeCurrent('existing')
                }
            })
        }
    }, [jwtToken])

    const onClick = useCallback(() => {
        if (sumToLoad && jwtToken) {
            setIsLoading(true);
            if (creditToken.token && chargeCurrent === 'existing') {
                charge_token(jwtToken, sumToLoad, JSON.stringify(formData), formData?.formType).then((res) => {
                    setIsLoading(false);
                    if (setShowLoadCredit) setShowLoadCredit(false);
                    dispatch(setShowAlert(true));
                    dispatch(setAlertVariant((res as SuccessType).result));
                    dispatch(setAlertText((res as SuccessType).operation));
                    props.hide();
                })
            } else if ((creditToken.token && chargeCurrent === 'new') || !creditToken.token) {
                charge(jwtToken, sumToLoad, saveToken ? 2 : 1, JSON.stringify(formData), formData?.formType).then((url) => {
                    url = new URL(url).href;
                    setSrc(url);
                    setIframeOpen(true);
                    setIsLoading(false);
                    // props.hide();
                })
            }

        }

    }, [sumToLoad, jwtToken, creditToken.token, chargeCurrent, saveToken, formData])

    return <Flex direction={'column'} style={{direction: 'rtl'}}>
        <TextField
            style={{direction: 'rtl', color: '#000C47'}}
            variation="quiet"
            descriptiveText={chargeCurrent === 'existing' ?
                <Text as={'span'} color={'#000C47'} fontSize={18}>חיוב כרטיס שמסתיים ב
                    - {creditToken.end_digits}</Text> : null}
            placeholder="00.00"
            label={<Text color={'#000C47'} fontSize={24}>הכנס את הסכום המבוקש לטעינה בשקלים - </Text>}
            errorMessage="There is an error"
            inputMode={'decimal'}
            value={sumToLoad}
            onChange={(e: any) => setSumToLoad(e.currentTarget.value)}
        />
        <RadioGroupField label="Row" name="row" direction="row" labelHidden value={chargeCurrent}
                         style={{direction: 'rtl', fontSize: 20, color: '#000C47'}}
                         onChange={(e) => setChargeCurrent(e.currentTarget.value as 'existing' | 'new')}>
            <Radio value="existing"><Text color={'#000C47'}>חיוב אשראי קיים</Text></Radio>
            <Radio value="new"><Text color={'#000C47'}>חיוב אשראי חדש</Text></Radio>
        </RadioGroupField>
        {chargeCurrent === 'new' && <CheckboxField
            name='save-token'
            value="yes"
            checked={saveToken}
            onChange={(e) => setSaveToken(e.target.checked)}
            label="שמור כרטיס"
        />}
        <Button isDisabled={!sumToLoad} isLoading={isLoading} onClick={onClick} backgroundColor={'#1B1A1A'}>טען
            קרדיט</Button>
        <IframeDialog src={src} open={iframeOpen} onClose={() => setIframeOpen(false)}/>
    </Flex>
}

export const ModalErrorContent: React.FC<ErrorModalProps> = (props) => {
    const {setShowLoadCredit, sendFormError} = props;

    const isPaymentError = useMemo(() => sendFormError?.error === 'paymentError' || sendFormError?.error === 'שגיאת קרדיט', [sendFormError.error])

    const button1Text = useMemo(() => {
        if (sendFormError.error === "authorizeError") {
            return 'התחברות'
        } else if (isPaymentError) {
            return 'טען קרדיט'
        }
        return 'אישור'
    }, [isPaymentError, sendFormError.error])

    const button2Text = useMemo(() => {
        if (sendFormError.error === "authorizeError") {
            return 'הרשמה'
        } else if (isPaymentError) {
            return 'סגור'
        }
        return 'סגור'
    }, [isPaymentError, sendFormError.error])

    const button1Action = useCallback(() => {
        if (sendFormError.error === "authorizeError") {
            alert('test me')
        } else if (isPaymentError) {
            setShowLoadCredit(true);
        } else {
            props.hide();
        }
    }, [isPaymentError, props, sendFormError.error, setShowLoadCredit])

    const button2Action = useCallback(() => {
        if (sendFormError.error === "authorizeError") {
            alert('test me')
        } else {
            props.hide();
        }
    }, [sendFormError.error, props])

    return <Flex direction={'column'} alignItems={'center'}>
        <Text fontSize={27} color={'#000C47'} fontWeight={400} paddingBottom={70}>{sendFormError?.error_details}</Text>
        <Button onClick={button1Action} borderRadius={27} width={212} variation={'primary'}>{button1Text}</Button>
        <Button onClick={button2Action} color={'#000C47'} border={'none'}>{button2Text}</Button>
    </Flex>
}
