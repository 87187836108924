import {GameForm} from "../../features/userInfo/userInfoSlice";
import {Flex} from "@aws-amplify/ui-react";
import React from "react";
import {BALL_SIZE} from "../regularLotto/regullarLottoRow";
import {RowItem} from "../../features/regularLotto/regularLottoSlice";
import {BsFillSuitClubFill, BsFillSuitDiamondFill, BsFillSuitHeartFill, BsFillSuitSpadeFill} from "react-icons/bs";
import {RowItem123} from "../../features/oneTwoThree/oneTwoThreeSlice";

export const FormInfo: React.FC<{ form: GameForm }> = (props) => {
    const {form} = props;
    if (form.form_kind === 'regular_chance' || form.form_kind === 'rav_chance') {
        return <Flex justifyContent={'center'} alignItems={'center'} key={form.id}>
            {form.marks['cards']['clover'][0] &&
            <Flex gap={2} alignItems={'center'}><BsFillSuitClubFill/> {form.marks['cards']['clover'][0]}</Flex>}
            {form.marks['cards']['diamond'][0] && <Flex gap={2} alignItems={'center'}><BsFillSuitDiamondFill
                color={'#FF1A1A'}/> {form.marks['cards']['diamond'][0]}</Flex>}
            {form.marks['cards']['heart'][0] && <Flex gap={2} alignItems={'center'}><BsFillSuitHeartFill
                color={'#FF1A1A'}/> {form.marks['cards']['heart'][0]}</Flex>}
            {form.marks['cards']['leaf'][0] &&
            <Flex gap={2} alignItems={'center'}><BsFillSuitSpadeFill/> {form.marks['cards']['leaf'][0]}</Flex>}
        </Flex>
    } else if (form.form_kind === '123') {
        return <Flex justifyContent={'center'} alignItems={'center'} direction={'column'} key={form.id}>
            {form.marks.tables.map((row: RowItem123) => {
                return <Flex direction={'row-reverse'} gap={12}
                             style={{cursor: 'pointer'}} wrap={'wrap'} marginBottom={10} justifyContent={'center'}>
                    {
                        Array.from(Array(3)).map((_, ballIndex) => {
                            return <Flex justifyContent={'center'} alignItems={'center'} borderRadius={35}
                                         backgroundColor={'#ffffff'} key={ballIndex}
                                         border={`1px solid #FF8355`}
                                         width={BALL_SIZE} height={BALL_SIZE}>
                                {row?.numbers[ballIndex + 1]! > -1 ? row?.numbers[ballIndex + 1] : ''}
                            </Flex>
                        })
                    }
                </Flex>
            })}
        </Flex>
    }
    return <Flex justifyContent={'center'} alignItems={'center'} direction={'column'} key={form.id}>
        {form.marks.tables.map((row: RowItem) => {
            if (!row.numbers.length) return <></>;
            return <Flex direction={'row'} gap={12} key={row.table_number} wrap={'wrap'}>
                {row.strong_number?.map((strongNumber: number) => {
                    return <Flex justifyContent={'center'} alignItems={'center'} borderRadius={35}
                                 backgroundColor={'#FF1A1A'}
                                 width={BALL_SIZE} height={BALL_SIZE}>
                        {strongNumber}
                    </Flex>
                })}
                {row.numbers?.map((number: number, ballIndex: number) => {
                    return <Flex justifyContent={'center'} alignItems={'center'} borderRadius={35}
                                 backgroundColor={'#ffffff'} key={ballIndex}
                                 border={`1px solid #FF1A1A`}
                                 width={BALL_SIZE} height={BALL_SIZE}>
                        {number}
                    </Flex>
                })}
            </Flex>
        })
        }
    </Flex>
}
