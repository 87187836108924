import {Theme} from "@aws-amplify/ui-react";

export const theme: Theme = {
    name: 'my-theme',
    tokens: {
        colors: {
            font: {
                primary: {value: '#000000'},
                // ...
            },
        },
        components: {
            button: {
                // this will affect the font weight of all button variants
                fontWeight: {value: '{fontWeights.extrabold}'},
                color: {value: '#ffffff'},
                borderRadius: {value: '11px'},
                _hover: {
                    backgroundColor: {value: 'rgba(27, 26, 26, 0.8)'},
                    color: {value: '#ffffff'},
                },
                _focus: {
                    backgroundColor: {value: 'rgba(27, 26, 26, 0.8)'},
                    color: {value: '#ffffff'},
                },
                _active: {
                    backgroundColor: {value: 'rgba(27, 26, 26, 0.6)'},
                    color: {value: '#ffffff'},
                },
                // style the primary variation
                primary: {
                    backgroundColor: {value: 'rgba(255, 26, 26, 1)'},
                    _hover: {
                        backgroundColor: {value: 'rgba(255, 26, 26, 0.8)'},
                    },
                    _focus: {
                        backgroundColor: {value: 'rgba(255, 26, 26, 0.8)'},
                    },
                    _active: {
                        backgroundColor: {value: 'rgba(255, 26, 26, 0.6)'},
                    },
                },
                menu: {
                    backgroundColor: {value: 'rgba(255, 26, 26, 1)'},
                    _hover: {
                        backgroundColor: {value: 'rgba(255, 26, 26, 0.8)'},
                    },
                    _focus: {
                        backgroundColor: {value: 'rgba(255, 26, 26, 0.8)'},
                    },
                    _active: {
                        backgroundColor: {value: 'rgba(255, 26, 26, 0.6)'},
                    },
                }
            },
            selectfield: {
                // color: {value: '{colors.blue.60}'},
                // fontSize: {value: '{fontSizes.large}'},
                borderColor: {value: '#FF1A1A'},
                _focus: {
                    borderColor: {value: '{colors.red.40}'},
                },
                label: {
                    // color: {value: '{colors.blue.60}'},
                },
            },
            radiogroup: {
                radio: {
                    borderWidth: {value: '{borderWidths.small}'},
                    borderColor: {value: '#FF1A1A'},
                    // backgroundColor: {value: '{colors.blue.20}'},
                    _checked: {
                        color: {value: '#FF1A1A'},
                    },
                    label: {
                        color: {value: 'black'},
                    },
                },
            },
        }
    },
};
