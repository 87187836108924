import {createAsyncThunk, createSlice, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import _ from "lodash";
import {
    CALCULATE_PRICE_CHANCE_SHITATI_PATH,
    CALCULATE_PRICE_RAV_CHANCE_PATH,
    CALCULATE_PRICE_REGULAR_CHANCE_PATH,
    calculatePrice, SEND_FORM_CHANCE_SHTATI_PATH,
    SEND_FORM_RAV_CHANCE_PATH,
    SEND_FORM_REGULAR_CHANCE_PATH,
    sendForm
} from "../regularLotto/regullarLottoApi";
import {AddNumberPayload, AutomaticFillPayload, RequestStatus} from '../tripleSeven/tripleSevenSlice';

export const chanceValues = ['7', '8', '9', '10', 'J', 'Q', 'K', 'A']

export interface ChanceRowItem {
    clover: string[];
    diamond: string[];
    heart: string[];
    leaf: string[];
}

type CardType = keyof ChanceRowItem;

export enum ChanceFormTypes {
    REGULAR_CHNACE = 'regular_chnace',
    RAV_CHANCE = 'rav_chance',
    CHANCE_SHITATI = 'chance_shitati',
}

export interface ChanceState {
    cards: ChanceRowItem;
    cost: number;
    multi_lottery: number;
    costStatus: RequestStatus;
    sendFormStatus: RequestStatus;
    maximumRowsAllowed: number;
    formType: ChanceFormTypes;
    formSize: number;
    maxSelectedNumbers: number;
    form_type: number;
    participant_amount: number;
    error?: any;
}

const initialState: ChanceState = {
    cards: {
        clover: [],
        diamond: [],
        heart: [],
        leaf: []
    },
    multi_lottery: -1,
    cost: 0,
    costStatus: 'idle',
    sendFormStatus: 'idle',
    maximumRowsAllowed: 4,
    formType: ChanceFormTypes.REGULAR_CHNACE,
    formSize: 4,
    maxSelectedNumbers: 4,
    participant_amount: 5,
    form_type: 4
};

function getPathByType(type: ChanceFormTypes): Record<'calculate' | 'send', URL> {
    switch (type) {
        case ChanceFormTypes.CHANCE_SHITATI:
            return {calculate: CALCULATE_PRICE_CHANCE_SHITATI_PATH, send: SEND_FORM_CHANCE_SHTATI_PATH};
        case ChanceFormTypes.RAV_CHANCE:
            return {calculate: CALCULATE_PRICE_RAV_CHANCE_PATH, send: SEND_FORM_RAV_CHANCE_PATH};
        case ChanceFormTypes.REGULAR_CHNACE:
        default:
            return {calculate: CALCULATE_PRICE_REGULAR_CHANCE_PATH, send: SEND_FORM_REGULAR_CHANCE_PATH};
    }
}

export const calculateGamePriceChance = createAsyncThunk(
    'chance/calculatePrice',
    async (data: any) => {
        const path = getPathByType(data.formType).calculate;
        const response = await calculatePrice(data, path);
        // The value we return becomes the `fulfilled` action payload
        return await response.json();
    }
);

export const sendChance = createAsyncThunk(
    'chance/sendForm',
    async (data: any, {rejectWithValue}) => {
        const path = getPathByType(data.data.formType).send;
        const response = await sendForm(data, path);
        if (response.status !== 200) {
            return rejectWithValue(await response.json())
        }
        return await response.json();
    }
);

export function getCardsTypeByNumberType(index: number): CardType {
    switch (index) {
        case 0:
            return 'clover';
        case 1:
            return 'diamond';
        case 2:
            return 'heart';
        case 3:
            return 'leaf';
        default:
            throw Error('index not match to chance type')
    }
}

export const chanceSlice = createSlice({
    name: 'chance',
    initialState,
    reducers: {
        addNumber: (state, action: PayloadAction<AddNumberPayload>) => {
            let cardsByType = state.cards[getCardsTypeByNumberType(action.payload.index)];
            if (state.formType === ChanceFormTypes.CHANCE_SHITATI) {
                if (cardsByType.length >= 4) {
                    return;
                }
            } else {
                cardsByType.length = 0;
            }
            cardsByType.push(chanceValues[action.payload.value - 1]);
        },
        removeNumber: (state, action: PayloadAction<AddNumberPayload>) => {
            let cardsByType = state.cards[getCardsTypeByNumberType(action.payload.index)];
            state.cards[getCardsTypeByNumberType(action.payload.index)] = cardsByType.filter((value) => value !== chanceValues[action.payload.value - 1]);
        },
        automaticFillRow: (state, action: PayloadAction<AddNumberPayload>) => {
            let cardsByType = state.cards[getCardsTypeByNumberType(action.payload.index)];
            cardsByType.length = 0;
            cardsByType.push(_.sample(chanceValues)!);
            if (state.formType === ChanceFormTypes.CHANCE_SHITATI) {
                cardsByType.push(_.sample(chanceValues)!);
                cardsByType.push(_.sample(chanceValues)!);
                cardsByType.push(_.sample(chanceValues)!);
            }
        },
        clearRow: (state, action: PayloadAction<number>) => {
            let cardsByType = state.cards[getCardsTypeByNumberType(action.payload)];
            cardsByType.length = 0;
        },
        clearTable: (state) => {
            state.cards = {
                clover: [],
                diamond: [],
                heart: [],
                leaf: []
            }
            state.cost = 0;
        },
        autoFillTable: (state, action: PayloadAction<AutomaticFillPayload>) => {
            state.cards = {
                clover: [],
                diamond: [],
                heart: [],
                leaf: []
            }
            let numOfValues = Object.values(state.cards).reduce((a: number, b: string[]) => a += +b.length, 0)
            while (numOfValues < state.maxSelectedNumbers) {
                console.log(numOfValues)
                const rowIndex = _.random(0, 3)
                let cardsByType = state.cards[getCardsTypeByNumberType(rowIndex)];
                if (
                    cardsByType.length > 0 &&
                    (state.formType !== ChanceFormTypes.CHANCE_SHITATI || cardsByType.length >= 4)
                ) {
                    continue;
                }
                let s = _.sample(chanceValues)!;
                if (!cardsByType.includes(s)) cardsByType.push(s);
                numOfValues = Object.values(state.cards).reduce((a: number, b: string[]) => a += +b.length, 0)
            }
        },
        setFormSize: (state, action: PayloadAction<number>) => {
            state.formSize = action.payload;
        },
        setFormType: (state, action: PayloadAction<ChanceFormTypes>) => {
            state.cards = {
                clover: [],
                diamond: [],
                heart: [],
                leaf: []
            }
            switch (action.payload) {
                case ChanceFormTypes.REGULAR_CHNACE:
                    state.formSize = 4;
                    state.form_type = 4;
                    state.maximumRowsAllowed = 4;
                    state.maxSelectedNumbers = 4;
                    state.participant_amount = 5;
                    break;
                case ChanceFormTypes.RAV_CHANCE:
                    state.formSize = 4;
                    state.form_type = 4;
                    state.maximumRowsAllowed = 4;
                    state.maxSelectedNumbers = 4;
                    state.participant_amount = 5;
                    break;
                case ChanceFormTypes.CHANCE_SHITATI:
                    state.formSize = 4;
                    state.form_type = 4
                    state.maximumRowsAllowed = 4;
                    state.maxSelectedNumbers = 4;
                    state.participant_amount = 5;
                    break;
            }
            state.formType = action.payload;
        },
        setMaxNumber: (state, action: PayloadAction<number>) => {
            state.maxSelectedNumbers = Number(action.payload);
            state.form_type = Number(action.payload);
        },
        setAmount: (state, action: PayloadAction<number>) => {
            state.participant_amount = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                isAnyOf(calculateGamePriceChance.pending), (state) => {
                    state.costStatus = 'loading';
                })
            .addMatcher(
                isAnyOf(calculateGamePriceChance.fulfilled), (state, action) => {
                    state.costStatus = 'idle';
                    state.cost = action.payload['price'];
                }
            )
            .addMatcher(
                isAnyOf(calculateGamePriceChance.rejected), (state) => {
                    state.costStatus = 'failed';
                }
            )
            .addMatcher(
                isAnyOf(sendChance.pending), (state) => {
                    state.sendFormStatus = 'loading';
                }
            )
            .addMatcher(
                isAnyOf(sendChance.fulfilled), (state, action) => {
                    state.sendFormStatus = 'idle';
                    // state.cost = action.payload['price'];
                }
            )
            .addMatcher(
                isAnyOf(sendChance.rejected), (state, action) => {
                    state.sendFormStatus = 'failed';
                    state.error = action.payload;
                }
            )
    },
});

export const {
    addNumber,
    removeNumber,
    automaticFillRow,
    clearRow,
    setFormSize,
    setFormType,
    clearTable,
    autoFillTable,
    setMaxNumber,
    setAmount,
} = chanceSlice.actions;
export const chanceData = (state: RootState) => state.chance;
export const chanceCards = (state: RootState) => state.chance.cards;
export const chanceCost = (state: RootState) => state.chance.cost;
export const chanceCostStatus = (state: RootState) => state.chance.costStatus;
export const chanceSendFormStatus = (state: RootState) => state.chance.sendFormStatus;
export const chanceSendFormError = (state: RootState) => state.chance.error;
export const chanceFormSize = (state: RootState) => state.chance.formSize;
export const chanceMaximumRowsAllowed = (state: RootState) => state.chance.maximumRowsAllowed;
export const chanceFormType = (state: RootState) => state.chance.formType;
export const chanceMaxNumber = (state: RootState) => state.chance.maxSelectedNumbers;
export const chanceParticipantAmount = (state: RootState) => state.chance.participant_amount;

export default chanceSlice.reducer;
