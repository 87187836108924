import React, {useEffect, useState} from "react";
import {
    Authenticator,
    CheckboxField,
    SelectField,
    TextField,
    useAuthenticator,
    View,
    Image, useTheme,
} from "@aws-amplify/ui-react";
import {I18n} from "aws-amplify";
import validator from 'validator'
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import { translations } from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('he');

I18n.putVocabularies({
    he: {
        'Enter your Email': 'מלא אימייל',
        'Enter your Password': 'מלא סיסמא',
        'Please confirm your Password': 'אנא אמת סיסמא',
        'Birthdate': 'תאריך לידה',
        'Family Name': 'שם משפחה',
        'Name': 'שם פרטי',
        'Enter your Family Name': 'מלא שם משפחה',
        'Enter your Name': 'מלא שם פרטי',
        'Reset Password': 'אפס סיסמא',
        'Incorrect username or password.': 'שם מתשמש או סיסמא שגויים',
        'Your passwords must match': 'הססמאות צריכות להיות זהות',
        'Password must have at least 8 characters': 'סיסמא צריכה להיות מעל 8 תווים',
        'Please fill out this field.': 'אנא מלא שדה זה',
        'Attributes did not conform to the schema: custom:ID: Unable to parse the number': 'תעודת זהות לא תקינה',
        'We Sent A Code': 'שלחנו לך קוד',
        'Your code is on the way. To log in, enter the code we texted to': 'הקוד שלך בדרך, להרשמה אנא הזן את הקוד אשר נשלח לנייד',
        'It may take a minute to arrive': 'יתכנו כמה רגעים עד להגעת הקוד',
        'reseller code': 'קוד משווק',
    },
});

export const Login: React.FC = () => {
    const components = {
        Header() {
            const {tokens} = useTheme();
            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Image
                        alt="Lottomatic logo"
                        src={process.env.PUBLIC_URL + "/assets/images/logo.svg"}
                    />
                </View>
            );
        },
        SignUp: {
            FormFields() {
                const {validationErrors} = useAuthenticator();

                return (
                    <>
                        <Authenticator.SignUp.FormFields/>

                        <TextField label={null} placeholder={'תעודת זהות'} name={'custom:ID'}/>
                        {/*<TextField label={'קוד משווק'} placeholder={'קוד משווק'} name={'custom:reseller_code'} defaultValue={resellerCode ? resellerCode.replace(',', '') : ''} isDisabled={!!resellerCode} />*/}
                        <SelectField label={null} placeholder="מין" name={'gender'}>
                            <option value="male">זכר</option>
                            <option value="female">נקבה</option>
                        </SelectField>
                        <CheckboxField
                            errorMessage={validationErrors.acknowledgement as string}
                            hasError={!!validationErrors.acknowledgement}
                            name="acknowledgement"
                            value="yes"
                            label="אני מאשר שאני מעל גיל 18, את תנאי השימוש באתר, את התקנון, ולקבל הודעות דיוור מהאתר."
                        />
                    </>
                );
            },
        },
    }
    // transfer this to use state
    // const formFields = {
    //     signUp: {
    //         phone_number: {
    //             dialCode: '+972',
    //             dialCodeList: ['+972'],
    //             placeholder: 'מספר טלפון'
    //         },
    //         'custom:reseller_code': {
    //             required: false,
    //             displayOrder: 10,
    //             value: '',
    //             disabled: false,
    //             placeholder: 'קוד משווק',
    //             label: 'קוד משווק',
    //         },
    //     },
    // }

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const resellerCode = query.get('resellerCode');

    const [formFields, setFormFields] = useState({
        signUp: {
            phone_number: {
                dialCode: '+972',
                dialCodeList: ['+972'],
                placeholder: 'מספר טלפון'
            },
            'custom:reseller_code': {
                required: false,
                displayOrder: 10,
                value: resellerCode?.replace(',', '') || '',
                disabled: false,
                placeholder: 'קוד משווק',
                label: 'קוד משווק',
            },
        },
    });

    const {route} = useAuthenticator((context) => [context.route]);
    // take resellerCode from query param if exists
    // useEffect(() => {
    //     console.log("resellerCode");
    //     if (resellerCode) {
    //         console.log("resellerCode2");
    //         // formFields.signUp['custom:reseller_code'].value = resellerCode.replace(',', '');
    //         // formFields.signUp['custom:reseller_code'].disabled = true;
    //         setFormFields({
    //             signUp: {
    //                 ...formFields.signUp,
    //                 'custom:reseller_code': {
    //                     ...formFields.signUp['custom:reseller_code'],
    //                     value: resellerCode.replace(',', ''),
    //                     disabled: true,
    //                 }
    //             }
    //         });
    //     }
    // }, [resellerCode]);
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || '/';
    useEffect(() => {
        if (route === 'authenticated') {
            navigate(from, {replace: true});
        }
    }, [route, navigate, from]);
    return <Authenticator variation={'modal'}
                          signUpAttributes={['birthdate', 'email', 'family_name', 'gender', 'name', 'phone_number']}
                          components={components}
                          initialState={ resellerCode ? 'signUp' : 'signIn' }
                          formFields={formFields}
                          services={{
                              async validateCustomSignUp(formData) {
                                  if (!validator.isEmail(formData.email)) {
                                      return {
                                          email: 'כתובת מייל לא תקינה',
                                          acknowledgement: '',
                                          name: '',
                                      }
                                  }
                                  if (!formData.acknowledgement) {
                                      return {
                                          email: '',
                                          acknowledgement: 'עליך לאשר את התנאים',
                                          name: '',
                                      }
                                  }
                                  if (!formData.name) {
                                      return {
                                          email: '',
                                          acknowledgement: '',
                                          name: 'שם לא תקין',
                                      }
                                  }
                                  if (!isValidIsraeliID(formData['custom:ID'])) {
                                      return {
                                          email: '',
                                          acknowledgement: '',
                                          name: 'תעודת זהות לא תקינה',
                                      }
                                  }
                              }
                          }}
    >
    </Authenticator>
}


function isValidIsraeliID(id: string): boolean {
    id = String(id).trim();
    if (id.length > 9 || id.length < 5 || isNaN(Number(id))) return false;

    // Pad string with zeros up to 9 digits
    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

    return Array
        .from(id, Number)
        .reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
}
