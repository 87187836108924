import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import timerReducer from '../features/timer/timerSlice';
import regularLottoReducer from '../features/regularLotto/regularLottoSlice'
import keyboardReducer from '../features/keyboard/keyboardSlice';
import appReducer from '../features/app/appSlice';
import userInfoReducer from '../features/userInfo/userInfoSlice';
import TripleSevenReducer from '../features/tripleSeven/tripleSevenSlice'
import OneTwoThreeReducer from '../features/oneTwoThree/oneTwoThreeSlice'
import ChanceReducer from '../features/chance/chanceSlice'
import ResultsReducer from '../features/results/resultsSlice'

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        timer: timerReducer,
        regularLotto: regularLottoReducer,
        keyboard: keyboardReducer,
        app: appReducer,
        userInfo: userInfoReducer,
        tripleSeven: TripleSevenReducer,
        oneTwoThree: OneTwoThreeReducer,
        chance: ChanceReducer,
        results: ResultsReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
