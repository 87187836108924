import React, {useEffect} from "react";
import {
    Flex,
    Placeholder,
    TabItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs
} from "@aws-amplify/ui-react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {getResults, getResultsRows, getResultStatus} from "./resultsSlice";
import {BALL_SIZE} from "../../components/regularLotto/regullarLottoRow";

export type MainGame = 'lotto' | 'chance' | 'tripleSeven' | 'oneTwoThree';

export const Results: React.FC = () => {
    return <Flex direction={'column'} padding={[4, 4, 4, 24]}>
        <Tabs spacing="equal" style={{direction: 'rtl'}}>
            <TabItem title="לוטו">
                <ResultTable gameType={'lotto'}/>
            </TabItem>
            <TabItem title="צ׳אנס">
                <ResultTable gameType={'chance'}/>
            </TabItem>
            <TabItem title="777">
                <ResultTable gameType={'tripleSeven'}/>
            </TabItem>
            <TabItem title="123">
                <ResultTable gameType={'oneTwoThree'}/>
            </TabItem>
        </Tabs>
    </Flex>
}

const ResultTable: React.FC<{ gameType: MainGame }> = (props) => {
    const resultsRows = useAppSelector(getResultsRows);
    const resultsStatus = useAppSelector(getResultStatus);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getResults(props.gameType))
    }, [dispatch, props.gameType])

    return resultsStatus === 'loading' ?
        <Flex direction={'column'} padding={24} style={{direction: 'rtl'}}>
            <Placeholder/>
            <Placeholder/>
            <Placeholder width={'50%'}/>
        </Flex> :
        <Table caption="" highlightOnHover style={{direction: 'rtl'}} size="small" variation="striped"
               textAlign={'center'}>
            <TableHead>
                <TableRow>
                    <TableCell as="th">מספר הגרלה</TableCell>
                    <TableCell as="th">תאריך</TableCell>
                    <TableCell as="th">תוצאות</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    resultsRows.map((result: any) => {
                        return <TableRow>
                            <TableCell>{result['מספר הגרלה']}</TableCell>
                            <TableCell>{result['תאריך']}</TableCell>
                            <TableCell>
                                <ResultRow row={result['תוצאות']}/>
                            </TableCell>
                        </TableRow>
                    })
                }
            </TableBody>
        </Table>
}

const ResultRow: React.FC<{ row: any }> = (props) => {
    return <Flex direction={'row'} gap={[6, 6, 6, 12]} wrap={'wrap'}
                 justifyContent={['flex-start', 'flex-start', 'flex-start', 'center']}>
        {props.row.strong_number && <Flex justifyContent={'center'} alignItems={'center'} borderRadius={35}
                                          backgroundColor={'#FF1A1A'}
                                          width={BALL_SIZE} height={BALL_SIZE}>
            {props.row.strong_number}
        </Flex>}
        {props.row.numbers?.map((number: number, ballIndex: number) => {
            return <Flex justifyContent={'center'} alignItems={'center'} borderRadius={35}
                         backgroundColor={'#ffffff'} key={ballIndex}
                         border={`1px solid #FF1A1A`}
                         width={BALL_SIZE} height={BALL_SIZE}>
                {number}
            </Flex>
        })}
    </Flex>
}
