import React from 'react'

export const Footer: React.FC = () => {
  return (
    <footer>
      <div className="container-fluid">
         <hr className="bottom_hr_line">
         </hr>
        <div className="row">
          <div className="col-lg-12">
            <div className="fotter_content">
              <p className="footer_text">AS.HR ADVANCE MARKETING LTD ©2022 Created by NiviX, Designed by Itamar Tuby.</p>
            </div>
          </div>
        </div>
        
      </div>
    </footer>
  )
}