import React, {useCallback, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Flex, Text} from "@aws-amplify/ui-react";
import {
    getSelectedRow,
    setSelectedRowIndex,
} from "../../features/keyboard/keyboardSlice";
import {
    addNumber,
    chanceCards,
    chanceValues,
    getCardsTypeByNumberType,
    removeNumber
} from "../../features/chance/chanceSlice";
import {BALL_SIZE} from "../regularLotto/regullarLottoRow";
import {BsFillSuitClubFill, BsFillSuitDiamondFill, BsFillSuitHeartFill, BsFillSuitSpadeFill} from "react-icons/bs";
import {makeStyles} from "@material-ui/core/styles";

interface RegularLottoRowProps {
    index: number;
}

const useStyle = makeStyles(({
    rowButton: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 35,
        width: BALL_SIZE,
        height: BALL_SIZE,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#40BC4C !important'
        }
    }
}))

export const ChanceRow: React.FC<RegularLottoRowProps> = (props) => {
    const {index} = props;
    const classes = useStyle();
    const dispatch = useAppDispatch();
    const tablesData = useAppSelector(chanceCards)
    const selectedRowIndex = useAppSelector(getSelectedRow);

    const cardType = useMemo(() => getCardsTypeByNumberType(index), [index]);

    const icon = useMemo(() => {
        const cardType = getCardsTypeByNumberType(index)
        switch (cardType) {
            case "clover":
                return <BsFillSuitClubFill/>
            case "diamond":
                return <BsFillSuitDiamondFill color={'#FF1A1A'}/>
            case "heart":
                return <BsFillSuitHeartFill color={'#FF1A1A'}/>
            case "leaf":
                return <BsFillSuitSpadeFill/>
        }
    }, [index])

    const onAddNumber = useCallback(async (value: number) => {
        const actionData = {
            index: index,
            value: value + 1
        };
        await dispatch(setSelectedRowIndex(index));
        if (tablesData[cardType].includes(chanceValues[value])) {
            dispatch(removeNumber(actionData))
        } else {
            dispatch(addNumber(actionData))
        }
    }, [dispatch, index, tablesData, cardType])

    return <Flex direction={'column'} gap={5} backgroundColor={index === selectedRowIndex ? '#d9ffd9' : '#ffffff'}
                 borderRadius={35} paddingInline={4} paddingBlock={8}>
        <Flex justifyContent={'flex-end'} fontSize={20} paddingInline={8}>
            {icon}
        </Flex>
        <Flex direction={'row'} gap={12} wrap={'wrap'} marginBottom={10} justifyContent={'flex-end'}>
            {
                chanceValues.map((val, ballIndex) => {
                    return <Flex backgroundColor={tablesData[cardType].includes(val) ? '#40bc4c' : '#ffffff'}
                                 key={ballIndex} border={`${index === selectedRowIndex ? '1px' : '0px'} solid #40BC4C`}
                                 className={classes.rowButton} onClick={() => onAddNumber(ballIndex)}>
                        <Text fontWeight={'bold'}
                              color={tablesData[cardType].includes(val) ? '#ffffff' : '#000000'}>{val}</Text>
                    </Flex>
                })
            }
        </Flex>
    </Flex>
}
