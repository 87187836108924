import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {
    CHANCE_RESULT_PATH,
    fetchResults,
    LOTTO_RESULT_PATH,
    ONE_TWO_THREE_RESULT_PATH,
    TRIPLE_SEVEN_RESULT_PATH
} from './resultsApi';
import {MainGame} from "./Results";

export interface NextGameData {
    time?: string;
    win_amount?: string;
}

export interface Results {
    rows: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: Results = {
    rows: [],
    status: 'idle',
};

function getPathByType(type: MainGame) {
    switch (type) {
        case "lotto":
            return LOTTO_RESULT_PATH;
        case "chance":
            return CHANCE_RESULT_PATH;
        case "oneTwoThree":
            return ONE_TWO_THREE_RESULT_PATH;
        case "tripleSeven":
            return TRIPLE_SEVEN_RESULT_PATH;
    }
}

export const getResults = createAsyncThunk(
    'results/getResults',
    async (gameType: MainGame) => {
        const path = getPathByType(gameType);
        const response = await fetchResults(path);
        return await response.json();
    }
);

export const resultsSlice = createSlice({
    name: 'results',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getResults.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getResults.fulfilled, (state, action) => {
                state.status = 'idle';
                state.rows = action.payload.data.rows;
            })
            .addCase(getResults.rejected, (state) => {
                state.status = 'failed';
            });
    },
});
export const getResultsRows = (state: RootState) => state.results.rows;
export const getResultStatus = (state: RootState) => state.results.status;

export default resultsSlice.reducer;
