import {
    Divider,
    Flex,
    Image,
    Menu, MenuButton,
    MenuItem,
    useAuthenticator,
    View,
    WithAuthenticatorProps
} from '@aws-amplify/ui-react'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useAppDispatch} from "../app/hooks";
import {getBalance} from "../features/app/appSlice";
import Avatar from '@mui/material/Avatar';
import {AiOutlineMenu} from "react-icons/ai";
import {useNavigate} from 'react-router-dom';
import {MdOutlineFaceUnlock} from "react-icons/md";
import {useLocation} from "react-router";

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name: string, size: number) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: size,
            height: size,
            alignSelf: 'center',
        },
        children: <MdOutlineFaceUnlock fontSize={size / 2}/>,
    };
}

export const Header: React.FC<WithAuthenticatorProps> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const {user} = useAuthenticator((context) => [context.user]);

    useEffect(() => {
        if (!user) return;
        const authToken = user.getSignInUserSession()?.getAccessToken().getJwtToken();
        if (!authToken) return;
        dispatch(getBalance(authToken))
    }, [dispatch, user])

    const fullName = useMemo(() => `${user?.attributes?.name} ${user?.attributes?.family_name}`, [user]);

    const returnHome = useCallback(() => navigate('/'), [navigate]);

    const goToUserInfo = useCallback(() => {
        if (location.pathname === '/user-info') {
            navigate(-1)
        } else {
            navigate('/user-info')
        }
    }, [location, navigate]);

    return (
        <>
            <Flex justifyContent={'space-between'} alignItems={'center'} as={'header'} paddingInline={[12, 12, 12, 32]}
                  paddingBlock={[4, 4, 4, 8]}>
                <Avatar onClick={goToUserInfo} style={{cursor: 'pointer'}} {...stringAvatar(fullName, 40)} />
                <Image width={['23%', '23%', '23%', 'auto']} alt={'logo'} onClick={returnHome}
                       src={process.env.PUBLIC_URL + "/assets/images/logo.svg"} style={{cursor: 'pointer'}}/>
                <Flex display={['flex', 'flex', 'flex', 'none']}>
                    <View width="100%">
                        <Menu style={{backgroundColor: 'white', direction: 'rtl'}} menuAlign={'end'} trigger={
                            <MenuButton style={{border: 'none', color: 'black', fontSize: 20}}>
                                <AiOutlineMenu/>
                            </MenuButton>
                        }>
                            <MenuItem onClick={() => navigate('terms-of-use')}>עזרה</MenuItem>
                            <MenuItem onClick={() => navigate('about-us')}>אודות</MenuItem>
                            <MenuItem onClick={() => navigate('articles')}>מאמרים</MenuItem>
                            <Divider />
                            <MenuItem onClick={() => navigate('contact-us')}>צור קשר</MenuItem>
                        </Menu>
                    </View>
                </Flex>
            </Flex>
            <hr className="header_hr_line m-0">
            </hr>
        </>
    )
}
