import {createAsyncThunk, createSlice, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {ResultStatus} from "../../utils/types";
import {sendLotto} from "../regularLotto/regularLottoSlice";
import {getUserBalanceApi} from "./appApi";
import {send777} from "../tripleSeven/tripleSevenSlice";
import {send123} from "../oneTwoThree/oneTwoThreeSlice";
import {sendChance} from "../chance/chanceSlice";

export interface AppState {
    userBalance: number;
    showAlert: boolean;
    alertVariant: ResultStatus;
    alertText: string;
    getUserBalanceStatus: 'idle' | 'loading' | 'failed';
}

const initialState: AppState = {
    userBalance: 0,
    showAlert: false,
    alertVariant: undefined,
    alertText: '',
    getUserBalanceStatus: 'idle',
};

export const getBalance = createAsyncThunk(
    'app/getBalance',
    async (authToken: string, {rejectWithValue}) => {
        const response = await getUserBalanceApi(authToken);
        if (response.status !== 200) {
            return rejectWithValue(await response.json())
        }
        return await response.json();
    }
);

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setShowAlert: (state, action: PayloadAction<boolean>) => {
            state.showAlert = action.payload;
        },
        setAlertVariant: (state, action: PayloadAction<ResultStatus>) => {
            state.alertVariant = action.payload;
        },
        setAlertText: (state, action: PayloadAction<string>) => {
            state.alertText = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getBalance.pending, (state) => {
                state.getUserBalanceStatus = 'loading';
            })
            .addCase(getBalance.fulfilled, (state, action) => {
                state.getUserBalanceStatus = 'idle';
                state.userBalance = action.payload;
            })
            .addCase(getBalance.rejected, (state, action) => {
                state.getUserBalanceStatus = 'failed';
                // @ts-ignore
                state.alertText = action.payload['error_details'];
                state.showAlert = true;
                state.alertVariant = 'error';
            })
            .addMatcher(
                isAnyOf(sendLotto.fulfilled, send777.fulfilled, send123.fulfilled, sendChance.fulfilled), (state, action) => {
                    state.showAlert = true;
                    state.alertVariant = 'success';
                    state.alertText = 'הטופס נשלח בהצלחה'
                }
            )
            .addMatcher(
                isAnyOf(sendLotto.rejected, send777.rejected, send123.rejected, sendChance.rejected), (state, action) => {
                    state.showAlert = true;
                    state.alertVariant = 'error';
                    state.alertText = 'אופס משהו קרה, אנא נסה שוב'
                }
            )
    }
});

export const {setAlertText, setAlertVariant, setShowAlert} = appSlice.actions;

export const getUserBalance = (state: RootState) => state.app.userBalance;
export const getShowAlert = (state: RootState) => state.app.showAlert;
export const getAlertVariant = (state: RootState) => state.app.alertVariant;
export const getAlertText = (state: RootState) => state.app.alertText;

export default appSlice.reducer;
