export const SERVER_URL = new URL('https://backend.lottomatic.io/')
// export const SERVER_URL = new URL('http://localhost:5000')
export const GET_TIMES_PATH = new URL('/games/time', SERVER_URL)

export type HebrewGamesTypes = 'לוטו' | "צ'אנס" | "123" | "777"

export enum GamesTypesEnum {
    lotto = 'לוטו',
    chance = "צ'אנס",
    oneTwoThree = '123',
    tripleSeven = '777',
}

export type GameTypes = keyof typeof GamesTypesEnum
export type TimesType = {
    [key in HebrewGamesTypes]: { time: string; win_amount: string; };
};

export async function fetchTimes(): Promise<Response> {
    return await fetch(GET_TIMES_PATH)
}
