import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {useLocation} from "react-router";

export const CREDIT_TRANSACTION_PATH = '/transaction'
export const BLUE_COLOR = '#263742'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
            height: 40,
            width: 120,
            backgroundColor: BLUE_COLOR
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            height: '80vh',
            width: '75%',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }),
);


type IframeDialogType = {
    open: boolean,
    onClose: (event: any, reason: any) => void,
    src: string
    type?: string
}

export const IframeDialog: React.FC<IframeDialogType> = ({open, onClose, src, type}) => {
    const classes = useStyles();
    const [imageHeight, setImageHeight] = useState(0);
    let location = useLocation()

    const onImgLoad = useCallback((e: any) => {
        setImageHeight(e.target.offsetHeight)
        console.log(`imageHeight: ${e.target.offsetHeight}`)
    }, [setImageHeight, imageHeight])


    useEffect(() => {
        console.log(location);
        if (location.pathname === CREDIT_TRANSACTION_PATH) {
            console.log(location);
        }
    }, [location])

    const iFrameTag = useMemo(() => {
        return <iframe src={src} width="100%" height="100%"
                       onLoad={() => console.log('iframe onload:' + location.pathname)}/>
    }, [src])

    return (
        <Modal
            className={classes.modal}
            open={open}
            closeAfterTransition
            onClose={onClose}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>

                    {src && type !== 'img' && iFrameTag}
                    <div style={{overflow: "auto", maxHeight: '100%'}}>
                        <div style={{overflow: 'hidden', height: imageHeight - 245}}>
                            {src && type === 'img' && <img onLoad={onImgLoad} src={src} style={{width: '100%'}}/>}
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>)
}
