import React, {useEffect, useMemo} from 'react'
import {useAppDispatch} from "../app/hooks";
import {getTimes} from "../features/timer/timerSlice";
import {Timer} from "../features/timer/Timer";
import {Link} from 'react-router-dom';
import {NavMenu} from "./navMenu";
import {Flex} from "@aws-amplify/ui-react";

export const DashboardCom: React.FC = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getTimes());
    }, [dispatch])
    return (
        <div>
            <section className="banner-section">
                <div className="container-fluid">
                    <div className="row align-items-center">

                        <div className="col-lg-12">
                            <h1 className="text-center">
                                שמעתם? כבר לא צריך לגשת<span className="fw-bolder"> לתחנה!</span>
                            </h1>
                            <p className="text-center mb-3 mb-sm-5">
                                ברוכים הבאים ללוטומטיק. הדרך שלכם לשלוח טפסי לוטו דרך הנייד בצורה דיגיטלית וקלילה.
                            </p>
                            <p className="text-center">לשליחת טפסים גללו למטה או הורידו את האפליקציה</p>
                            <div className="pt-3 pb-2 d-flex justify-content-center flex-row app-download-icons">
                                <a href="https://play.google.com/store/apps/details?id=com.lottomatic.io">
                                    <img className="img-fluid"
                                         src={process.env.PUBLIC_URL + "/assets/images/google-play.svg"} alt="12"/>
                                </a>
                                <a href="itms-apps://apple.com/app/1582434986" className="ms-2">
                                    <img className="img-fluid"
                                         src={process.env.PUBLIC_URL + "/assets/images/app-store.svg"} alt="12"/>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="grid-sec">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-9">
                            <hr className="bottom_hr_line"/>
                            <div className="row d-none d-lg-flex">
                                <div className="col-lg-5 detail_center_content">
                                    <h4 className="text-end">משחקים קרובים:</h4>
                                </div>
                                <div className="col-lg-7 detail_center_content">
                                    <h4 className="text-end">כל המשחקים -</h4>
                                </div>
                            </div>
                            <div className="row flex-column-reverse flex-sm-row mt-2">
                                <div className="col-sm-5">
                                    <div className="d-flex light-bg">
                                        <div className="text-center flex-fill col-reverse">
                                            <Link to={'/results'} style={{textDecoration: 'none', color: '#171717'}}>
                                                <span className="mb-2">ארכיון תוצאות</span>
                                            </Link>
                                            <Link to={'/lotto-index'} className={'custom-btn'}>שחק עכשיו</Link>
                                        </div>
                                        <Timer gameType={'lotto'}/>
                                    </div>
                                </div>
                                <div className="col-sm-7">
                                    <img className="img-fluid img_100"
                                         src={process.env.PUBLIC_URL + "/assets/images/img-1.jpg"} alt="11"/>
                                </div>
                            </div>
                            <div className="row flex-column-reverse flex-sm-row mt-2">
                                <div className="col-sm-5">
                                    <div className="d-flex light-bg">
                                        <div className="text-center flex-fill col-reverse">
                                            <Link to={'/results'} style={{textDecoration: 'none', color: '#171717'}}>
                                                <span className="mb-2">ארכיון תוצאות</span>
                                            </Link>
                                            <Link to={'/chance-index'} className={'custom-btn'}>שחק עכשיו</Link>
                                        </div>
                                        <Timer gameType={'pais'}/>
                                    </div>
                                </div>
                                <div className="col-sm-7">
                                    <img className="img-fluid img_100"
                                         src={process.env.PUBLIC_URL + "/assets/images/img-2.jpg"} alt="11"/>
                                </div>
                            </div>
                            <div className="row flex-column-reverse flex-sm-row mt-2">
                                <div className="col-sm-5">
                                    <div className="d-flex light-bg">
                                        <div className="text-center flex-fill col-reverse">
                                            <Link to={'/results'} style={{textDecoration: 'none', color: '#171717'}}>
                                                <span className="mb-2">ארכיון תוצאות</span>
                                            </Link>
                                            <Link to={'/777-index'} className={'custom-btn'}>שחק עכשיו</Link>
                                        </div>
                                        <Timer gameType={'tripleSeven'}/>
                                    </div>
                                </div>
                                <div className="col-sm-7">
                                    <img className="img-fluid img_100"
                                         src={process.env.PUBLIC_URL + "/assets/images/img-3.jpg"} alt="11"/>
                                </div>
                            </div>
                            <div className="row flex-column-reverse flex-sm-row mt-2">
                                <div className="col-sm-5">
                                    <div className="d-flex light-bg">
                                        <div className="text-center flex-fill col-reverse">
                                            <Link to={'/results'} style={{textDecoration: 'none', color: '#171717'}}>
                                                <span className="mb-2">ארכיון תוצאות</span>
                                            </Link>
                                            <Link to={'/123'} className={'custom-btn'}>שחק עכשיו</Link>
                                        </div>
                                        <Timer gameType={'oneTwoThree'}/>
                                    </div>
                                </div>
                                <div className="col-sm-7">
                                    <img className="img-fluid img_100"
                                         src={process.env.PUBLIC_URL + "/assets/images/img-4.jpg"} alt="11"/>
                                </div>
                            </div>

                            <div className="row flex-column-reverse flex-sm-row mt-2">
                                <div className="col-sm-5">
                                    <Flex height={'100%'} alignItems={'center'}>
                                        <div className="text-center flex-fill col-reverse">
                                            <a href={'https://www.gerud.net/'} target={'_blank'} className={'custom-btn'}>שחק עכשיו</a>
                                        </div>
                                    </Flex>
                                </div>
                                <div className="col-sm-7">
                                    <img className="img-fluid img_100"
                                         src={process.env.PUBLIC_URL + "/assets/images/img.jpg"} alt="11"/>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3">
                            <Flex display={['none', 'none', 'none', 'flex']} justifyContent={'flex-end'}>
                                <NavMenu/>
                            </Flex>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    )
}
